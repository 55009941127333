export default function statusTransaksi(status) {
	switch (status) {
		case 0:
			return "Pending";
		case 1:
			return "Berhasil";
		case 2:
			return "Gagal";
		default:
			return "Undefined";
	}
}
