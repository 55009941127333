export default function InputSearch({ label, name, value, onChange }) {
	return (
		<div className="flex justify-between items-center my-1 md:text-lg">
			<p className="w-1/2 text-sm text-gray-700 dark:text-gray-400">{label}</p>
			<input
				className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
				type="search"
				name={name}
				value={value}
				onChange={(e) => {
					onChange(e);
				}}
			/>
		</div>
	);
}
