import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

export default function Sidebar() {
  const [listTransaksiOpen, setListTransaksiOpen] = useState(false);
  const { pathname } = useLocation();
  const activePathname = `/${pathname.split("/")[1]}`;
  return (
    <div className="z-20 flex-shrink-0 hidden w-64 overflow-y-auto bg-black md:block bg-hero bg-no-repeat bg-cover bg-center bg-blend-overlay bg-opacity-70">
      <div className="py-4 text-white dark:text-gray-200">
        <div className="">
          <span className="ml-6 text-center text-lg font-bold text-white dark:text-gray-200">
            CUSO Mobile Admin
          </span>
        </div>
        <ul className="mt-6">
          <li
            className={`relative px-6 py-3 ${
              activePathname === "/" ? "bg-gray-200 bg-opacity-20" : ""
            }`}
            onClick={() => setListTransaksiOpen(false)}
          >
            {activePathname === "/" ? (
              <span
                className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                aria-hidden="true"
              ></span>
            ) : (
              <></>
            )}
            <Link
              className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-500 dark:hover:text-gray-200"
              to="/"
            >
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
              </svg>
              <span className="ml-4">Dashboard</span>
            </Link>
          </li>
        </ul>
        <ul>
          <li
            className={`relative px-6 py-3 ${
              activePathname === "/deposit-topup"
                ? "bg-gray-200 bg-opacity-20"
                : ""
            }`}
            onClick={() => setListTransaksiOpen(false)}
          >
            {activePathname === "/deposit-topup" ? (
              <span
                className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                aria-hidden="true"
              ></span>
            ) : (
              <></>
            )}
            <Link
              className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-500 dark:hover:text-gray-200"
              to="/deposit-topup"
            >
              <svg
                className="w-5 h-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                />
              </svg>
              <span className="ml-4">Deposit Topup</span>
            </Link>
          </li>
          <li
            className={`relative px-6 py-3 ${
              activePathname === "/deposit-setoran"
                ? "bg-gray-200 bg-opacity-20"
                : ""
            }`}
            onClick={() => setListTransaksiOpen(false)}
          >
            {activePathname === "/deposit-setoran" ? (
              <span
                className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                aria-hidden="true"
              ></span>
            ) : (
              <></>
            )}
            <Link
              className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-500 dark:hover:text-gray-200"
              to="/deposit-setoran"
            >
              <svg
                className="w-5 h-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                />
              </svg>
              <span className="ml-4">Deposit Setoran</span>
            </Link>
          </li>
          <li
            className={`relative px-6 py-3 ${
              activePathname === "/list-transaksi-ppob" ||
              activePathname === "/list-transaksi-via-bank"
                ? "bg-gray-200 bg-opacity-20"
                : ""
            }`}
            onClick={() => setListTransaksiOpen(!listTransaksiOpen)}
          >
            {activePathname === "/list-transaksi-ppob" ||
            activePathname === "/topup-via-bank" ? (
              <span
                className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                aria-hidden="true"
              ></span>
            ) : (
              <></>
            )}
            <button className="inline-flex justify-between items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-500 dark:hover:text-gray-200 focus:outline-none">
              <span className="inline-flex items-center">
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"></path>
                </svg>
                <span className="ml-4">List Transaksi</span>
              </span>
              <svg
                className="w-4 h-4"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </button>
          </li>
          {listTransaksiOpen && (
            <ul
              className="pl-12 space-y-2 overflow-hidden text-sm font-medium dark:text-gray-400"
              aria-label="submenu"
            >
              <li
                className={`px-3 py-3 transition-colors duration-150 hover:text-gray-600 dark:hover:text-gray-200
								${activePathname === "/list-transaksi-ppob" ? "text-gray-400" : "text-white"}
								`}
              >
                <Link to="/list-transaksi-ppob">Transaksi PPOB</Link>
              </li>
              <li
                className={`px-3 py-3 transition-colors duration-150 hover:text-gray-600 dark:hover:text-gray-200
								${activePathname === "/topup-via-bank" ? "text-gray-400" : "text-white"}
								`}
              >
                <Link to="/topup-via-bank">Topup Via Bank</Link>
              </li>
              {+localStorage.getItem("id") === 0 && (
                <li
                  className={`px-3 py-3 transition-colors duration-150 hover:text-gray-600 dark:hover:text-gray-200
								${activePathname === "/list-transaksi" ? "text-gray-400" : "text-white"}
								`}
                >
                  <Link to="/list-transaksi">All Transaksi</Link>
                </li>
              )}
            </ul>
          )}
          {/* <li
            className={`relative px-6 py-3 ${
              activePathname === "/list-anggota"
                ? "bg-gray-200 bg-opacity-20"
                : ""
            }`}
          >
            {activePathname === "/list-anggota" ? (
              <span
                className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                aria-hidden="true"
              ></span>
            ) : (
              <></>
            )}
            <Link
              className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-500 dark:hover:text-gray-200"
              to="/list-anggota"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                />
              </svg>
              <span className="ml-4">List Anggota</span>
            </Link>
          </li> */}
          <li
            className={`relative px-6 py-3 ${
              activePathname === "/info-produk"
                ? "bg-gray-200 bg-opacity-20"
                : ""
            }`}
          >
            {activePathname === "/info-produk" ? (
              <span
                className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                aria-hidden="true"
              ></span>
            ) : (
              <></>
            )}
            <Link
              className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-500 dark:hover:text-gray-200"
              to="/info-produk"
            >
              <svg
                className="w-5 h-5"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                />
              </svg>
              <span className="ml-4">Info Produk</span>
            </Link>
          </li>
          <li
            className={`relative px-6 py-3 ${
              activePathname === "/list-berita" ? "bg-gray-200 bg-opacity-20" : ""
            }`}
            onClick={() => setListTransaksiOpen(false)}
          >
            {activePathname === "/list-berita" ? (
              <span
                className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                aria-hidden="true"
              ></span>
            ) : (
              <></>
            )}
            <Link
              className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-500 dark:hover:text-gray-200"
              to="/list-berita"
            >
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"></path>
              </svg>
              <span className="ml-4">Berita</span>
            </Link>
          </li>

              {/**=======CDM-15 Ruang Usaha======= */}
          <li
            className={`relative px-6 py-3 ${
              activePathname === "/list-ruang-usaha" ? "bg-gray-200 bg-opacity-20" : ""
            }`}
            onClick={() => setListTransaksiOpen(false)}
          >
            {activePathname === "/list-ruang-usaha" ? (
              <span
                className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                aria-hidden="true"
              ></span>
            ) : (
              <></>
            )}
            <Link
              className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-500 dark:hover:text-gray-200"
              to="/list-ruang-usaha"
            >
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              > 
                <path d="M6 2L3 6v14c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2V6l-3-4H6zM3.8 6h16.4M16 10a4 4 0 1 1-8 0"></path>
              </svg>
              <span className="ml-4">Ruang Usaha</span>
            </Link>
          </li>
          {/**=======CDM-15 ENd======= */}

          {/**=======CDM-15 Banner======= */}
          <li
            className={`relative px-6 py-3 ${
              activePathname === "/list-banner" ? "bg-gray-200 bg-opacity-20" : ""
            }`}
            onClick={() => setListTransaksiOpen(false)}
          >
            {activePathname === "/list-banner" ? (
              <span
                className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                aria-hidden="true"
              ></span>
            ) : (
              <></>
            )}
            <Link
              className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-500 dark:hover:text-gray-200"
              to="/list-banner"
            >
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              > 
                <path d="M20.59 13.41l-7.17 7.17a2 2 0 0 1-2.83 0L2 12V2h10l8.59 8.59a2 2 0 0 1 0 2.82z"></path>
              </svg>
              <span className="ml-4">Banner</span>
            </Link>
          </li>
          {/**=======CDM-15 ENd======= */}

          {/* DISABLE NOTIFICATION PAGE */}
          {/* <li
            className={`relative px-6 py-3 ${
              activePathname === "/push-notifikasi"
                ? "bg-gray-200 bg-opacity-20"
                : ""
            }`}
            onClick={() => setListTransaksiOpen(false)}
          >
            {activePathname === "/push-notifikasi" ? (
              <span
                className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                aria-hidden="true"
              ></span>
            ) : (
              <></>
            )}
            <Link
              className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-500 dark:hover:text-gray-200"
              to="/push-notifikasi"
            >
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"></path>
              </svg>
              <span className="ml-4">Notifikasi</span>
            </Link>
          </li> */}

          {/* <li
						className={`relative px-6 py-3 ${
							activePathname === "/component-page"
								? "bg-gray-200 bg-opacity-20"
								: ""
						}`}
						onClick={() => setListTransaksiOpen(false)}
					>
						{activePathname === "/component-page" ? (
							<span
								className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
								aria-hidden="true"
							></span>
						) : (
							<></>
						)}
						<Link
							className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-500 dark:hover:text-gray-200"
							to="/component-page"
						>
							<svg
								className="w-5 h-5"
								aria-hidden="true"
								fill="none"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								viewBox="0 0 24 24"
								stroke="currentColor"
							>
								<path d="M4 6h16M4 10h16M4 14h16M4 18h16"></path>
							</svg>
							<span className="ml-4">All Components</span>
						</Link>
					</li> */}
          {+localStorage.getItem("id") === 0 && (
            <li
              className={`relative px-6 py-3 ${
                activePathname === "/user-management"
                  ? "bg-gray-200 bg-opacity-20"
                  : ""
              }`}
              onClick={() => setListTransaksiOpen(false)}
            >
              {activePathname === "/user-management" ? (
                <span
                  className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                  aria-hidden="true"
                ></span>
              ) : (
                <></>
              )}
              <Link
                className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-500 dark:hover:text-gray-200"
                to="/user-management"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  aria-hidden="true"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
                <span className="ml-4">User Management</span>
              </Link>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}
