import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory, useParams } from "react-router-dom";
import Row from "./Row";
import NominalCard from "../Card/NominalCard";
import TransaksiCard from "../Card/TransaksiCard";
import InputSearch from "../Form/InputSearch";
import ButtonSearch from "../Button/ButtonSearch";
import ButtonLoading from "../Button/ButtonLoading";
import { Loading, Error } from "../index";
import {
  fetchDepositTopup,
  searchDepositTopup,
} from "../../store/actions/topupAction";
import { addCommas, removeNonNumeric } from "../../utils";
import ReactExport from "react-export-excel";

export default function Table() {
  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const { depositTopup, totalSaldo, isLoading, isLoadingButton, isError } =
    useSelector((state) => state.topup);
  const [tipeUser] = useState(Number(localStorage.getItem("id")));
  const [currentData, setCurrentData] = useState([]);
  const [totalKoperasi, setTotalKoperasi] = useState(0);
  const [paging, setPaging] = useState({
    startNumber: 0,
    limit: 10,
    canNext: true,
    canPrev: false,
    lastIdx: null,
  });
  const [input, setInput] = useState({
    CU_Id: query.get("CU_Id") || "",
    Nama_CU: query.get("Nama_CU") || "",
    Area: query.get("Area") || "",
    Min_Saldo: query.get("Min_Saldo") || "",
    Max_Saldo: query.get("Max_Saldo") || "",
  });

  useEffect(() => {
    const expired = +localStorage.getItem("expired")
    const now = new Date()

    if(localStorage.getItem("expired") && expired < now.getTime()){
        localStorage.clear();
        window.location.reload()
    }
    
    let url = "dtopup/list";
    let urlQuery = "dtopup/list/?";
    const arrQuery = [];
    for (const key in input) {
      if (input[key].length > 0) {
        if (key === "Min_Saldo" || key === "Max_Saldo") {
          arrQuery.push(`${key}=${removeNonNumeric(input[key])}`);
        } else {
          arrQuery.push(`${key}=${input[key]}`);
        }
      }
    }
    urlQuery += arrQuery.join("&");
    if (urlQuery[urlQuery.length - 1] !== "?") {
      url = urlQuery;
    }
    if (arrQuery.length) {
      dispatch(searchDepositTopup(url));
    } else {
      dispatch(fetchDepositTopup());
    }
  }, [params]);

  const next = () => {
    if (paging.limit + 10 >= depositTopup.length) {
      setPaging({
        ...paging,
        startNumber: paging.startNumber + 10,
        limit: paging.limit + 10,
        canNext: false,
        canPrev: true,
      });
    } else {
      setPaging({
        ...paging,
        startNumber: paging.startNumber + 10,
        limit: paging.limit + 10,
        canNext: true,
        canPrev: true,
      });
    }

    const tempData = [];
    if (paging.startNumber >= -10) {
      for (
        let i = paging.startNumber + 10;
        i < paging.limit + 10 && i < depositTopup.length;
        i++
      ) {
        tempData.push(depositTopup[i]);
      }
      setCurrentData(tempData);
    } else {
      setCurrentData([]);
    }
  };

  const prev = () => {
    if (paging.startNumber - 10 <= 0) {
      setPaging({
        ...paging,
        startNumber: paging.startNumber - 10,
        limit: paging.limit - 10,
        canNext: true,
        canPrev: false,
      });
    } else {
      setPaging({
        ...paging,
        startNumber: paging.startNumber - 10,
        limit: paging.limit - 10,
        canNext: true,
        canPrev: true,
      });
    }

    const tempData = [];
    if (paging.startNumber >= 10 && paging.startNumber < depositTopup.length) {
      for (let i = paging.startNumber - 10; i < paging.limit - 10; i++) {
        tempData.push(depositTopup[i]);
      }
      setCurrentData(tempData);
    } else if (
      paging.startNumber >= 10 &&
      paging.lastIdx + 1 === depositTopup.length
    ) {
      for (let i = paging.startNumber - 10; i < paging.lastIdx + 1; i++) {
        tempData.push(depositTopup[i]);
      }
      setCurrentData(tempData);
    } else {
      setCurrentData([]);
    }
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    if (name === "Min_Saldo" || name === "Max_Saldo") {
      setInput({
        ...input,
        [name]: addCommas(removeNonNumeric(value)),
      });
    } else {
      setInput({
        ...input,
        [name]: value,
      });
    }
  };

  const searchHandler = (e) => {
    e.preventDefault();
    let params = new URLSearchParams();
    for (const i in input) {
      if (input[i].length > 0) {
        if (i === "Min_Saldo" || i === "Max_Saldo") {
          params.append(i, removeNonNumeric(input[i]));
        } else {
          params.append(i, input[i]);
        }
      }
    }
    history.push({ search: params.toString() });
  };
  useEffect(() => {
    if (depositTopup.length) {
      // pagination
      if (10 >= depositTopup[depositTopup.length - 1].idx + 1) {
        setPaging({
          ...paging,
          startNumber: 0,
          limit: 10,
          canNext: false,
          canPrev: false,
          lastIdx: depositTopup[depositTopup.length - 1].idx,
        });
      } else {
        setPaging({
          ...paging,
          startNumber: 0,
          limit: 10,
          canNext: true,
          canPrev: false,
          lastIdx: depositTopup[depositTopup.length - 1].idx,
        });
      }
      const tempData = [];
      for (let i = 0; i < 10 && i < depositTopup.length; i++) {
        tempData.push(depositTopup[i]);
      }
      setCurrentData(tempData);

      // total koperasi
      setTotalKoperasi(depositTopup.length);
    } else {
      setCurrentData([]);
      setPaging({
        ...paging,
        startNumber: 0,
        limit: 10,
        canNext: false,
        canPrev: false,
        lastIdx: 0,
      });
    }
  }, [depositTopup]);

  //excel
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

  if (isLoading)
    return (
      <>
        <Loading />
      </>
    );

  if (isError)
    return (
      <>
        <Error />
      </>
    );

  return (
    <main className="pb-10">
      <div className="container grid px-6 mx-auto">
        <h2 className="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
          List Deposit Topup
        </h2>
        {tipeUser === 0 && (
          <>
            <div className="grid md:grid-cols-4 grid-cols-1 gap-4">
              <TransaksiCard title="Total Koperasi" transaksi={totalKoperasi} />
              <NominalCard title="Total Saldo" nominal={totalSaldo} />
            </div>
            <div className="flex flex-col md:flex-row md:justify-between md:gap-8 px-4 my-3">
              <div className="w-full flex flex-col justify-between">
                <InputSearch
                  label="Koperasi ID"
                  name="CU_Id"
                  value={input.CU_Id}
                  onChange={(e) => {
                    onChangeHandler(e);
                  }}
                />
                <InputSearch
                  label="Koperasi"
                  name="Nama_CU"
                  value={input.Nama_CU}
                  onChange={(e) => {
                    onChangeHandler(e);
                  }}
                />
                <InputSearch
                  label="Area"
                  name="Area"
                  value={input.Area}
                  onChange={(e) => {
                    onChangeHandler(e);
                  }}
                />
              </div>
              <div className="w-full flex flex-col justify-between">
                <InputSearch
                  label="Min Saldo"
                  name="Min_Saldo"
                  value={input.Min_Saldo}
                  onChange={(e) => {
                    onChangeHandler(e);
                  }}
                />
                <InputSearch
                  label="Max Saldo"
                  name="Max_Saldo"
                  value={input.Max_Saldo}
                  onChange={(e) => {
                    onChangeHandler(e);
                  }}
                />
                {isLoadingButton ? (
                  <ButtonLoading />
                ) : (
                  <ButtonSearch onClick={(e) => searchHandler(e)} />
                )}
              </div>
            </div>
          </>
        )}
        <div className="w-full overflow-hidden rounded-lg shadow-md">
          <div className="sticky top-0 w-full overflow-x-auto h-85vh">
            <table className="relative w-full whitespace-no-wrap">
              <thead>
                <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800">
                  <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800">
                    No
                  </th>
                  <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800">
                    Koperasi Id
                  </th>
                  <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800">
                    Area
                  </th>
                  <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800">
                    Koperasi
                  </th>
                  <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800">
                    Saldo
                  </th>
                  <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                {currentData.map((d, index) => {
                  return (
                    <Row
                      key={index}
                      data={d}
                      idx={index + paging.startNumber}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="flex justify-between flex-wrap px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 sm:grid-cols-9 dark:text-gray-400 dark:bg-gray-800">
            <span className="flex items-center">
              <span>
                Showing{" "}
                {depositTopup.length > 0
                  ? `${paging.startNumber + 1}-${
                      paging.startNumber + currentData.length
                    } of ${depositTopup.length} |`
                  : "0-0 of 0 |"}
              </span>
              <span>
                <ExcelFile
                  element={
                    <button className="flex w-full items-center justify-center px-4 py-2 text-sm font-medium leading-5 text-green-600 hover:text-green-700 focus:outline-none focus:shadow-outline-green">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                        />
                      </svg>
                      <span className="ml-2">Download as Excel</span>
                    </button>
                  }
                  filename="Deposit Setoran"
                >
                  <ExcelSheet data={depositTopup} name="Deposit Setoran">
                    <ExcelColumn label="No" value={(col) => col.idx + 1} />
                    <ExcelColumn label="CU ID" value="CU_Id" />
                    <ExcelColumn label="Area" value="Area" />
                    <ExcelColumn label="Koperasi" value="Nama_CU" />
                    <ExcelColumn label="Saldo" value="Deposit_Topup" />
                  </ExcelSheet>
                </ExcelFile>
              </span>
            </span>
            <span className="col-span-2"></span>
            <span className="flex mt-2 col-span-4 sm:mt-auto justify-end">
              <nav aria-label="Table navigation">
                <ul className="inline-flex items-center py-2">
                  <li className="mx-2">
                    <button
                      className={`px-3 py-1 rounded-md rounded-l-lg focus:outline-none focus:shadow-outline-purple ${
                        paging.canPrev
                          ? "bg-purple-600 hover:bg-purple-800"
                          : "bg-purple-300 cursor-default"
                      } text-white`}
                      disabled={paging.canPrev ? false : true}
                      onClick={prev}
                    >
                      PREV
                    </button>
                  </li>
                  <li>
                    <button
                      className={`px-3 py-1 rounded-md rounded-l-lg focus:outline-none focus:shadow-outline-purple ${
                        paging.canNext
                          ? "bg-purple-600 hover:bg-purple-800"
                          : "bg-purple-300 cursor-default"
                      } text-white`}
                      disabled={paging.canNext ? false : true}
                      onClick={next}
                    >
                      NEXT
                    </button>
                  </li>
                </ul>
              </nav>
            </span>
          </div>
        </div>
      </div>
    </main>
  );
}
