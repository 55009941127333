import { useSelector, useDispatch } from "react-redux";
import { useLocation, Link } from "react-router-dom";
import { setMenuBarOpen } from "../store/actions/userAction";
import { useState } from "react";

export default function MobileSidebar() {
  const { isSideMenuOpen } = useSelector((state) => state.user);
  const { pathname } = useLocation();
  const activePathname = `/${pathname.split("/")[1]}`;
  const dispatch = useDispatch();

  const closeMenuBar = () => {
    dispatch(setMenuBarOpen(false));
  };

  const [listTransaksiOpen, setListTransaksiOpen] = useState(false);

  return (
    <div>
      {isSideMenuOpen && (
        <div
          onClick={() => closeMenuBar()}
          className="fixed inset-0 z-10 flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center md:hidden"
        ></div>
      )}
      <aside
        className={
          isSideMenuOpen
            ? "fixed inset-y-0 z-20 flex-shrink-0 w-64 mt-16 overflow-y-auto bg-white dark:bg-gray-800 md:hidden"
            : "fixed hidden inset-y-0 z-20 flex-shrink-0 w-64 mt-16 overflow-y-auto bg-white dark:bg-gray-800 md:hidden"
        }
      >
        <div className="py-4 text-gray-500 dark:text-gray-400">
          <div className="">
            <span className="ml-6 text-center text-lg font-bold text-gray-400 dark:text-gray-200">
              CUSO Mobile Admin
            </span>
          </div>
          <ul className="mt-6">
            <li
              className={`relative px-6 py-3 ${
                activePathname === "/" ? "bg-black bg-opacity-30" : ""
              }`}
              onClick={() => closeMenuBar()}
            >
              {activePathname === "/" ? (
                <span
                  className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                  aria-hidden="true"
                ></span>
              ) : (
                <></>
              )}
              <Link
                className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-500 dark:hover:text-gray-200"
                to="/"
              >
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path>
                </svg>
                <span className="ml-4">Dashboard</span>
              </Link>
            </li>
          </ul>
          <ul>
            <li
              className={`relative px-6 py-3 ${
                activePathname === "/deposit-topup"
                  ? "bg-black bg-opacity-30"
                  : ""
              }`}
              onClick={() => closeMenuBar()}
            >
              {activePathname === "/deposit-topup" ? (
                <span
                  className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                  aria-hidden="true"
                ></span>
              ) : (
                <></>
              )}
              <Link
                className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-500 dark:hover:text-gray-200"
                to="/deposit-topup"
              >
                <svg
                  className="w-5 h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                  />
                </svg>
                <span className="ml-4">Deposit Topup</span>
              </Link>
            </li>
            <li
              className={`relative px-6 py-3 ${
                activePathname === "/deposit-setoran"
                  ? "bg-black bg-opacity-30"
                  : ""
              }`}
              onClick={() => closeMenuBar()}
            >
              {activePathname === "/deposit-setoran" ? (
                <span
                  className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                  aria-hidden="true"
                ></span>
              ) : (
                <></>
              )}
              <Link
                className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-500 dark:hover:text-gray-200"
                to="/deposit-setoran"
              >
                <svg
                  className="w-5 h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                  />
                </svg>
                <span className="ml-4">Deposit Setoran</span>
              </Link>
            </li>
            <li
              className={`relative px-6 py-3 ${
                activePathname === "/list-transaksi-ppob" ||
                activePathname === "/list-transaksi-via-bank"
                  ? "bg-black bg-opacity-30"
                  : ""
              }`}
              onClick={() => setListTransaksiOpen(!listTransaksiOpen)}
            >
              {activePathname === "/list-transaksi-ppob" ||
              activePathname === "/topup-via-bank" ? (
                <span
                  className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                  aria-hidden="true"
                ></span>
              ) : (
                <></>
              )}
              <button className="inline-flex justify-between items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-500 dark:hover:text-gray-200 focus:outline-none">
                <span className="inline-flex items-center">
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"></path>
                  </svg>
                  <span className="ml-4">List Transaksi</span>
                </span>
                <svg
                  className="w-4 h-4"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </button>
            </li>
            {listTransaksiOpen && (
              <ul
                className="pl-12 space-y-2 overflow-hidden text-sm font-medium dark:text-gray-400"
                aria-label="submenu"
              >
                <li
                  className={`px-3 py-3 transition-colors duration-150 hover:text-gray-600 dark:hover:text-gray-200
								${activePathname === "/list-transaksi-ppob" ? "text-gray-600" : "text-gray-400"}
								`}
                >
                  <Link to="/list-transaksi-ppob">Transaksi PPOB</Link>
                </li>
                <li
                  className={`px-3 py-3 transition-colors duration-150 hover:text-gray-600 dark:hover:text-gray-200
								${activePathname === "/topup-via-bank" ? "text-gray-600" : "text-gray-400"}
								`}
                >
                  <Link to="/topup-via-bank">Topup Via Bank</Link>
                </li>
                {+localStorage.getItem("id") === 0 && (
                  <li
                    className={`px-3 py-3 transition-colors duration-150 hover:text-gray-600 dark:hover:text-gray-200
								${activePathname === "/list-transaksi" ? "text-gray-600" : "text-gray-400"}
								`}
                  >
                    <Link to="/list-transaksi">All Transaksi</Link>
                  </li>
                )}
              </ul>
            )}
            <li
              className={`relative px-6 py-3 ${
                activePathname === "/info-produk"
                  ? "bg-black bg-opacity-30"
                  : ""
              }`}
              onClick={() => closeMenuBar()}
            >
              {activePathname === "/info-produk" ? (
                <span
                  className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                  aria-hidden="true"
                ></span>
              ) : (
                <></>
              )}
              <Link
                className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-500 dark:hover:text-gray-200 dark:text-gray-100"
                to="/info-produk"
              >
                <svg
                  className="w-5 h-5"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M7 21h10a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                  />
                </svg>
                <span className="ml-4">Info Produk</span>
              </Link>
            </li>
            <li
              className={`relative px-6 py-3 ${
                activePathname === "/berita" ? "bg-black bg-opacity-30" : ""
              }`}
              onClick={() => closeMenuBar()}
            >
              {activePathname === "/berita" ? (
                <span
                  className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                  aria-hidden="true"
                ></span>
              ) : (
                <></>
              )}
              <Link
                className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-500 dark:hover:text-gray-200"
                to="/berita"
              >
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M8 16H6a2 2 0 01-2-2V6a2 2 0 012-2h8a2 2 0 012 2v2m-6 12h8a2 2 0 002-2v-8a2 2 0 00-2-2h-8a2 2 0 00-2 2v8a2 2 0 002 2z"></path>
                </svg>
                <span className="ml-4">Berita</span>
              </Link>
            </li>
            <li
              className={`relative px-6 py-3 ${
                activePathname === "/push-notifikasi"
                  ? "bg-black bg-opacity-30"
                  : ""
              }`}
              onClick={() => closeMenuBar()}
            >
              {activePathname === "/push-notifikasi" ? (
                <span
                  className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                  aria-hidden="true"
                ></span>
              ) : (
                <></>
              )}
              <Link
                className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-500 dark:hover:text-gray-200"
                to="/push-notifikasi"
              >
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M10 2a6 6 0 00-6 6v3.586l-.707.707A1 1 0 004 14h12a1 1 0 00.707-1.707L16 11.586V8a6 6 0 00-6-6zM10 18a3 3 0 01-3-3h6a3 3 0 01-3 3z"></path>
                </svg>
                <span className="ml-4">Notifikasi</span>
              </Link>
            </li>
            <li
              className={`relative px-6 py-3 ${
                activePathname === "/component-page"
                  ? "bg-black bg-opacity-30"
                  : ""
              }`}
              onClick={() => closeMenuBar()}
            >
              {activePathname === "/component-page" ? (
                <span
                  className="absolute inset-y-0 left-0 w-1 bg-purple-600 rounded-tr-lg rounded-br-lg"
                  aria-hidden="true"
                ></span>
              ) : (
                <></>
              )}
              <Link
                className="inline-flex items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-500 dark:hover:text-gray-200"
                to="/component-page"
              >
                <svg
                  className="w-5 h-5"
                  aria-hidden="true"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M4 6h16M4 10h16M4 14h16M4 18h16"></path>
                </svg>
                <span className="ml-4">All Components</span>
              </Link>
            </li>
          </ul>
        </div>
      </aside>
    </div>
  );
}
