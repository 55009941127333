import axios from "../../config/axiosinstance";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { Router } from "react-router-dom";

//Fetch CU
export const fetchCU = (payload) => {
	return (dispatch) => {
		dispatch({
			type: "SET_RUANG_USAHA_LOADING",
		});
		axios({
			method: "GET",
			url: "rusaha/cu",
			headers: {
				token: localStorage.getItem("token"),
			},
		})
			.then(({ data }) => {
				dispatch({
					type: "SET_LIST_CU",
					payload: data.data,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
};

//Fetch InfoProduk
export const fetchInfoProduk = (payload) => {
	return (dispatch) => {
		dispatch({
			type: "SET_LIST_INFO_PRODUK",
		});
		axios({
			method: "GET",
			url: `infoproduk/${payload}`,
			headers: {
				token: localStorage.getItem("token"),
			},
		})
			.then(({ data }) => {
				dispatch({
					type: "SET_LIST_INFO_PRODUK",
					payload: data.data,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
};

//Fetch Current Produk
export const fetchCurrentProduk = (koperasi, produk) => {
	return (dispatch) => {
		dispatch({
			type: "SET_LIST_INFO_PRODUK",
		});
		axios({
			method: "GET",
			url: `infoproduk/${koperasi}`,
			headers: {
				token: localStorage.getItem("token"),
			},
		})
			.then(({ data }) => {
				const current = data.data.find((item) => item.Kode_Produk == produk);
				dispatch({
					type: "SET_CURRENT_PRODUK",
					payload: current,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
};

//Update Info Produk
export const updateInfoProduk = (payload, id) => {
	return (dispatch) => {
		dispatch({
			type: "SET_INFO_PRODUK_LOADING",
		});
		axios({
			method: "PUT",
			url: `infoproduk/${id}`,
			headers: {
				"Content-Type": "multipart/form-data",
				token: localStorage.getItem("token"),
			},
			data: payload,
		})
			.then((res) => {
                Swal.fire({
                    title: 'Saved',
                    text: "Produk Berhasil Diperbarui",
                    icon: 'success',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Okay!',
                    allowOutsideClick: false
                  }).then((result) => {
                    if (result.isConfirmed) {
						window.location.reload();
                    }
                })
			})
			.catch((err) => {
				Swal.fire({
                    title: "Oops!",
                    text: "Pembaruan Belum Berhasil!",
                    icon: "error",
                    showConfirmButton: true,
                    position: "center",
                    allowOutsideClick: false,
                  });
			});
	};
};
