import axios from "../../config/axiosinstance";

export const fetchDepositTopup = (payload) => {
	return (dispatch) => {
		dispatch({
			type: "SET_TOPUP_LOADING",
		});
		axios({
			method: "GET",
			url: "dtopup/list",
			headers: {
				token: localStorage.getItem("token"),
			},
		})
			.then(({ data }) => {
				data.data.forEach((element, idx) => {
					element.idx = idx;
				});
				dispatch({
					type: "SET_DEPOSIT_TOPUP",
					payload: data.data,
				});
				dispatch({
					type: "SET_SALDO_TOPUP",
					payload: data.totalSaldo,
				});
			})
			.catch(({ response }) => {
				dispatch({
					type: "SET_TOPUP_ERROR",
				});
			});
	};
};

export const searchDepositTopup = (payload) => {
	return (dispatch) => {
		dispatch({
			type: "SET_BUTTON_TOPUP_LOADING",
		});
		axios({
			method: "GET",
			url: payload,
			headers: {
				token: localStorage.getItem("token"),
			},
		})
			.then(({ data }) => {
				data.data.forEach((element, idx) => {
					element.idx = idx;
				});
				dispatch({
					type: "SET_DEPOSIT_TOPUP",
					payload: data.data,
				});
				dispatch({
					type: "SET_SALDO_TOPUP",
					payload: data.totalSaldo,
				});
			})
			.catch((err) => {
				dispatch({
					type: "SET_TOPUP_ERROR",
				});
			});
	};
};

export const fetchHistoryTopup = (id) => {
	return (dispatch) => {
		dispatch({
			type: "SET_TOPUP_LOADING",
		});
		axios({
			method: "GET",
			url: `dtopup/history/${id}`,
			headers: {
				token: localStorage.getItem("token"),
			},
		})
			.then(({ data }) => {
				data.data.forEach((element, idx) => {
					element.idx = idx;
				});
				dispatch({
					type: "SET_HISTORY_TOPUP",
					payload: data.data,
					namaKoperasi: data.namaKoperasi,
				});
			})
			.catch((err) => {
				dispatch({
					type: "SET_TOPUP_ERROR",
				});
			});
	};
};

export const searchHistoryTopup = (payload) => {
	return (dispatch) => {
		dispatch({
			type: "SET_BUTTON_TOPUP_LOADING",
		});
		axios({
			method: "GET",
			url: payload,
			headers: {
				token: localStorage.getItem("token"),
			},
		})
			.then(({ data }) => {
				data.data.forEach((element, idx) => {
					element.idx = idx;
				});
				dispatch({
					type: "SET_HISTORY_TOPUP",
					payload: data.data,
					namaKoperasi: data.namaKoperasi,
				});
			})
			.catch((err) => {
				dispatch({
					type: "SET_TOPUP_ERROR",
				});
			});
	};
};
