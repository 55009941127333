import axios from "../../config/axiosinstance";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

//Create New Ruang Usaha
export const postRuangUsaha = (payload) => {
	return (dispatch) => {
		dispatch({
			type: "SET_RUANG_USAHA_LOADING",
		});
		axios({
			method: "POST",
			url: "rusaha",
			headers: {
				"Content-Type": "multipart/form-data",
				token: localStorage.getItem("token"),
			},
			data: payload,
		})
			.then((res) => {
                Swal.fire({
                    title: 'Saved',
                    text: "Produk Berhasil Ditambahkan",
                    icon: 'success',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Okay!',
                    allowOutsideClick: false
                  }).then((result) => {
                    if (result.isConfirmed) {
                      window.location.reload();
                    }
                })
			})
			.catch((err) => {
				Swal.fire({
                    title: "Oops!",
                    text: "Penyimpanan Belum Berhasil!",
                    icon: "error",
                    showConfirmButton: true,
                    position: "center",
                    allowOutsideClick: false,
                  });
			});
	};
};

//Fetch Category
export const fetchCategory = (payload) => {
	return (dispatch) => {
		dispatch({
			type: "SET_RUANG_USAHA_LOADING",
		});
		axios({
			method: "GET",
			url: "rusaha/category",
			headers: {
				token: localStorage.getItem("token"),
			},
		})
			.then(({ data }) => {
				dispatch({
					type: "SET_LIST_CATEGORY",
					payload: data.data,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
};

//Fetch CU
export const fetchCU = (payload) => {
	return (dispatch) => {
		dispatch({
			type: "SET_RUANG_USAHA_LOADING",
		});
		axios({
			method: "GET",
			url: "rusaha/cu",
			headers: {
				token: localStorage.getItem("token"),
			},
		})
			.then(({ data }) => {
				dispatch({
					type: "SET_LIST_CU",
					payload: data.data,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
};

//Fetch RuangUsaha
export const fetchRuangUsaha = (payload) => {
	return (dispatch) => {
		dispatch({
			type: "SET_LIST_RUANG_USAHA",
		});
		axios({
			method: "GET",
			url: "rusaha",
			headers: {
				token: localStorage.getItem("token"),
			},
		})
			.then(({ data }) => {
				let filtered = []
				if(localStorage.getItem("CU_Id") === "null"){
					filtered = data.data.filter(item => item.Status_Banner === 1 ).reverse()
				}else{
					filtered = data.data.filter(item => item.Status_Banner === 1 && item.CU_Id == localStorage.getItem("CU_Id")).reverse()
				}
				dispatch({
					type: "SET_LIST_RUANG_USAHA",
					payload: filtered,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
};

//Search RuangUsaha
export const searchRuangUsaha = (payload) => {
	return (dispatch) => {
		dispatch({
			type: "SET_LIST_RUANG_USAHA",
		});
		axios({
			method: "GET",
			url: `rusaha/search/${payload}`,
			headers: {
				token: localStorage.getItem("token"),
			},
		})
			.then(({ data }) => {
				let filtered = []
				if(localStorage.getItem("CU_Id") === "null"){
					filtered = data.data.filter(item => item.Status_Banner === 1 ).reverse()
				}else{
					filtered = data.data.filter(item => item.Status_Banner === 1 && item.CU_Id == localStorage.getItem("CU_Id")).reverse()
				}
				dispatch({
					type: "SET_LIST_RUANG_USAHA",
					payload: filtered,
				});
			})
			.catch((err) => {
				Swal.fire({
                    title: 'Oops!',
                    text: "Produk Tidak Ditemukan",
                    icon: 'error',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Okay!',
                    allowOutsideClick: false
                  }).then((result) => {
                    if (result.isConfirmed) {
                      window.location.reload();
                    }
                })
			});
	};
};

//Delete RuangUsaha
export const deleteRuangUsaha = (payload) => {
	return (dispatch) => {
		dispatch({
			type: "SET_LIST_RUANG_USAHA",
		});
		axios({
			method: "DELETE",
			url: `rusaha/${payload}`,
			headers: {
				token: localStorage.getItem("token"),
			},
		})
			.then(() => {
				Swal.fire({
                    title: 'Berhasil',
                    text: "Produk Berhasil Dihapus",
                    icon: 'success',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Okay!',
                    allowOutsideClick: false
                  }).then((result) => {
                    if (result.isConfirmed) {
                      window.location.reload();
                    }
                  })
			})
			.catch((err) => {
				Swal.fire({
                    title: "Oops!",
                    text: "Produk Belum Berhasil Dihapus!",
                    icon: "error",
                    showConfirmButton: true,
                    position: "center",
                    allowOutsideClick: false,
                  });
			});
	};
};

//Fetch Banner
export const fetchBanner = (payload) => {
	return (dispatch) => {
		dispatch({
			type: "SET_LIST_BANNER",
		});
		axios({
			method: "GET",
			url: "rusaha",
			headers: {
				token: localStorage.getItem("token"),
			},
		})
			.then(({ data }) => {
				let filtered = []
				if(localStorage.getItem("CU_Id") === "null"){
					filtered = data.data.filter(item => item.Status_Banner === 0 ).reverse()
				}else{
					filtered = data.data.filter(item => item.Status_Banner === 0 && item.CU_Id == localStorage.getItem("CU_Id")).reverse()
				}
				dispatch({
					type: "SET_LIST_BANNER",
					payload: filtered,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
};

//Search Banner
export const searchBanner = (payload) => {
	return (dispatch) => {
		dispatch({
			type: "SET_LIST_BANNER",
		});
		axios({
			method: "GET",
			url: `rusaha/search/${payload}`,
			headers: {
				token: localStorage.getItem("token"),
			},
		})
			.then(({ data }) => {
				let filtered = []
				if(localStorage.getItem("CU_Id") === "null"){
					filtered = data.data.filter(item => item.Status_Banner === 0 ).reverse()
				}else{
					filtered = data.data.filter(item => item.Status_Banner === 0 && item.CU_Id == localStorage.getItem("CU_Id")).reverse()
				}
				dispatch({
					type: "SET_LIST_BANNER",
					payload: filtered,
				});
			})
			.catch((err) => {
				Swal.fire({
                    title: 'Oops!',
                    text: "Produk Tidak Ditemukan",
                    icon: 'error',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Okay!',
                    allowOutsideClick: false
                  }).then((result) => {
                    if (result.isConfirmed) {
                      window.location.reload();
                    }
                })
			});
	};
};

//Filter Koperasi Ruang Usaha
export const filterKoperasiRuangUsaha = (CU_Id, data) => {
	return (dispatch) => {
		dispatch({
			type: "SET_LIST_RUANG_USAHA",
		});
		const filtered = data.filter(item => item.CU_Id === CU_Id).reverse()
		if(filtered.length !== 0){
			dispatch({
				type: "SET_LIST_RUANG_USAHA",
				payload: filtered,
			});
		}else{
			Swal.fire({
				title: 'Oops!',
				text: "Produk Tidak Ditemukan",
				icon: 'error',
				confirmButtonColor: '#3085d6',
				confirmButtonText: 'Okay!',
				allowOutsideClick: false
			  }).then((result) => {
				if (result.isConfirmed) {
				  window.location.reload();
				}
			})
		}
	};
};

//Filter Koperasi Banner
export const filterKoperasiBanner = (CU_Id, data) => {
	return (dispatch) => {
		dispatch({
			type: "SET_LIST_BANNER",
		});
		const filtered = data.filter(item => item.CU_Id === CU_Id).reverse()
		if(filtered.length !== 0){
			dispatch({
				type: "SET_LIST_BANNER",
				payload: filtered,
			});
		}else{
			Swal.fire({
				title: 'Oops!',
				text: "Produk Tidak Ditemukan",
				icon: 'error',
				confirmButtonColor: '#3085d6',
				confirmButtonText: 'Okay!',
				allowOutsideClick: false
			  }).then((result) => {
				if (result.isConfirmed) {
				  window.location.reload();
				}
			})
		}
	};
};

//Fetch Current Ruang Usaha
export const fetchCurrentUsaha = (kode) => {
	return (dispatch) => {
		dispatch({
		type: "SET_RUANG_USAHA_LOADING",
		});
		axios({
			method: "GET",
			url: `rusaha/${kode}`,
			headers: {
				token: localStorage.getItem("token"),
			},
		})
			.then(({ data }) => {
				if(data.data.Status_Banner === 1){	
					dispatch({
						type: "SET_CURRENT_RUANG_USAHA",
						payload: data.data,
					});
				}else{
					Swal.fire({
						title: "Oops!",
						text: "Data Ini Bukan Ruang Usaha!",
						icon: "error",
						showConfirmButton: true,
						position: "center",
						allowOutsideClick: false,
					  });
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
};

//Update Ruang Usaha
export const updateRuangUsaha = (payload, id) => {
	return (dispatch) => {
		dispatch({
			type: "SET_RUANG_USAHA_LOADING",
		});
		axios({
			method: "PUT",
			url: `rusaha/${id}`,
			headers: {
				"Content-Type": "multipart/form-data",
				token: localStorage.getItem("token"),
			},
			data: payload,
		})
			.then((res) => {
                Swal.fire({
                    title: 'Saved',
                    text: "Produk Berhasil Diperbarui",
                    icon: 'success',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Okay!',
                    allowOutsideClick: false
                  }).then((result) => {
                    if (result.isConfirmed) {
						window.location.reload();
                    }
                })
			})
			.catch((err) => {
				Swal.fire({
                    title: "Oops!",
                    text: "Pembaruan Belum Berhasil!",
                    icon: "error",
                    showConfirmButton: true,
                    position: "center",
                    allowOutsideClick: false,
                  });
			});
	};
};

//Fetch Current Banner
export const fetchCurrentBanner = (kode) => {
	return (dispatch) => {
		dispatch({
		type: "SET_RUANG_USAHA_LOADING",
		});
		axios({
			method: "GET",
			url: `rusaha/${kode}`,
			headers: {
				token: localStorage.getItem("token"),
			},
		})
			.then(({ data }) => {
				if(data.data.Status_Banner === 0){	
					dispatch({
						type: "SET_CURRENT_BANNER",
						payload: data.data,
					});
				}else{
					Swal.fire({
						title: "Oops!",
						text: "Data Ini Bukan Banner!",
						icon: "error",
						showConfirmButton: true,
						position: "center",
						allowOutsideClick: false,
					  });
				}
			})
			.catch((err) => {
				console.log(err);
			});
	};
};

//Update Ruang Usaha
export const updateBanner = (payload, id) => {
	return (dispatch) => {
		dispatch({
			type: "SET_RUANG_USAHA_LOADING",
		});
		axios({
			method: "PUT",
			url: `rusaha/${id}`,
			headers: {
				"Content-Type": "multipart/form-data",
				token: localStorage.getItem("token"),
			},
			data: payload,
		})
			.then((res) => {
                Swal.fire({
                    title: 'Saved',
                    text: "Produk Berhasil Diperbarui",
                    icon: 'success',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Okay!',
                    allowOutsideClick: false
                  }).then((result) => {
                    if (result.isConfirmed) {
						window.location.reload();
                    }
                })
			})
			.catch((err) => {
				Swal.fire({
                    title: "Oops!",
                    text: "Pembaruan Belum Berhasil!",
                    icon: "error",
                    showConfirmButton: true,
                    position: "center",
                    allowOutsideClick: false,
                  });
			});
	};
};