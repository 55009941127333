import axios from "../../config/axiosinstance";

export const fetchCUId = () => {
  return (dispatch) => {
    dispatch({
      type: "SET_USER_MANAGEMENT_LOADING",
      payload: true,
    });
    axios({
      url: "muser/load",
      method: "GET",
      headers: {
        token: localStorage.getItem("token"),
      },
    })
      .then(({ data }) => {
        dispatch({
          type: "SET_USER_MANAGEMENT_LIST_KOPERASI",
          payload: data.listKoperasi,
        });
      })
      .catch(({ response }) => {
        console.log(response);
      });
  };
};

export const fetchUsers = () => {
  return (dispatch) => {
    dispatch({
      type: "SET_USER_MANAGEMENT_LOADING",
      payload: true,
    });
    axios({
      url: "muser",
      method: "GET",
      headers: {
        token: localStorage.getItem("token"),
      },
    })
      .then(({ data }) => {
        dispatch({
          type: "SET_USER_MANAGEMENT_LIST_USER",
          payload: data.users,
        });
      })
      .catch(({ response }) => {
        console.log(response);
      });
  };
};

export const addUser = (payload) => {
  return (dispatch) => {
    dispatch({
      type: "SET_USER_MANAGEMENT_LOADING_BUTTON",
      payload: true,
    });
    axios({
      url: "muser/register",
      method: "POST",
      headers: {
        token: localStorage.getItem("token"),
      },
      data: payload,
    })
      .then(({ data }) => {
        console.log(data, "success");
      })
      .catch(({ response }) => {
        console.log(response);
      });
  };
};
