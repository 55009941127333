import axios from "../../config/axiosinstance";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

//Create New Berita
export const postBerita = (payload) => {
	return (dispatch) => {
		dispatch({
			type: "SET_BERITA_LOADING",
		});
		axios({
			method: "POST",
			url: "news",
			headers: {
				"Content-Type": "multipart/form-data",
				token: localStorage.getItem("token"),
			},
			data: payload,
		})
			.then((res) => {
                Swal.fire({
                    title: 'Saved',
                    text: "Produk Berhasil Ditambahkan",
                    icon: 'success',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Okay!',
                    allowOutsideClick: false
                  }).then((result) => {
                    if (result.isConfirmed) {
                      window.location.reload();
                    }
                })
			})
			.catch((err) => {
				Swal.fire({
                    title: "Oops!",
                    text: "Penyimpanan Belum Berhasil!",
                    icon: "error",
                    showConfirmButton: true,
                    position: "center",
                    allowOutsideClick: false,
                  });
			});
	};
};

//Fetch berita
export const fetchBerita = (payload) => {
	return (dispatch) => {
		dispatch({
			type: "SET_LIST_BERITA",
		});
		axios({
			method: "GET",
			url: "news",
			headers: {
				token: localStorage.getItem("token"),
			},
		})
			.then(({ data }) => {
				let filtered = []
				if(localStorage.getItem("CU_Id") === "null"){
					filtered = data.data.reverse()
				}else{
					filtered = data.data.filter(item => item.CU_Id == localStorage.getItem("CU_Id")).reverse()
				}
				dispatch({
					type: "SET_LIST_BERITA",
					payload: filtered,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
};

//Fetch CU
export const fetchCU = (payload) => {
	return (dispatch) => {
		dispatch({
			type: "SET_RUANG_USAHA_LOADING",
		});
		axios({
			method: "GET",
			url: "rusaha/cu",
			headers: {
				token: localStorage.getItem("token"),
			},
		})
			.then(({ data }) => {
				dispatch({
					type: "SET_LIST_CU",
					payload: data.data,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
};

//Filter Koperasi Berita
export const filterKoperasiBerita = (CU_Id, data) => {
	return (dispatch) => {
		dispatch({
			type: "SET_LIST_BERITA",
		});
		const filtered = data.filter(item => item.CU_Id === CU_Id).reverse()
		if(filtered.length !== 0){
			dispatch({
				type: "SET_LIST_BERITA",
				payload: filtered,
			});
		}else{
			Swal.fire({
				title: 'Oops!',
				text: "Berita Tidak Ditemukan",
				icon: 'error',
				confirmButtonColor: '#3085d6',
				confirmButtonText: 'Okay!',
				allowOutsideClick: false
			  }).then((result) => {
				if (result.isConfirmed) {
				  window.location.reload();
				}
			})
		}
	};
};

//Fetch Kategori
export const fetchKategori = (payload) => {
	return (dispatch) => {
		dispatch({
			type: "SET_BERITA_LOADING",
		});
		axios({
			method: "GET",
			url: "news/list",
			headers: {
				token: localStorage.getItem("token"),
			},
		})
			.then(({ data }) => {
				dispatch({
					type: "SET_LIST_CATEGORY",
					payload: data.data,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
};

//Fetch Current Berita
export const fetchCurrentBerita = (kode) => {
	return (dispatch) => {
		dispatch({
		type: "SET_BERITA_LOADING",
		});
		axios({
			method: "GET",
			url: `news/${kode}`,
			headers: {
				token: localStorage.getItem("token"),
			},
		})
		
		.then(({ data }) => {
			dispatch({
				type: "SET_CURRENT_BERITA",
				payload: data.data,
			});
		}) 
		.catch((err) => {
			console.log(err);
		});
	};
};

//Update Berita
export const updateBerita = (payload, id) => {
	return (dispatch) => {
		dispatch({
			type: "SET_BERITA_LOADING",
		});
		axios({
			method: "PUT",
			url: `news/${id}`,
			headers: {
				"Content-Type": "multipart/form-data",
				token: localStorage.getItem("token"),
			},
			data: payload,
		})
			.then((res) => {
                Swal.fire({
                    title: 'Saved',
                    text: "Berita Berhasil Diperbarui",
                    icon: 'success',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Okay!',
                    allowOutsideClick: false
                  }).then((result) => {
                    if (result.isConfirmed) {
						window.location.reload();
                    }
                })
			})
			.catch((err) => {
				Swal.fire({
                    title: "Oops!",
                    text: "Pembaruan Belum Berhasil!",
                    icon: "error",
                    showConfirmButton: true,
                    position: "center",
                    allowOutsideClick: false,
                  });
			});
	};
};
