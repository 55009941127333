import axios from "../../config/axiosinstance";

export const fetchListTransaksiPPOB = (payload) => {
  return (dispatch) => {
    dispatch({
      type: "SET_LIST_TRANSAKSI_LOADING",
    });
    axios({
      method: "GET",
      url: "ppobrev",
      headers: {
        token: localStorage.getItem("token"),
      },
    })
      .then(({ data }) => {
        data.data.forEach((element, idx) => {
          element.idx = idx;
        });
        dispatch({
          type: "SET_LIST_TRANSAKSI_PPOB",
          payload: data.data,
          payload2: {
            totalCUSOPoint1: data.totalCUSOPoint1,
            totalCUSOPoint2: data.totalCUSOPoint2,
            totalRevShareCOOP: data.totalRevShareCOOP,
            totalRevShareCUSO: data.totalRevShareCUSO,
            totalTransaksi: data.totalTransaksi,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "SET_LIST_TRANSAKSI_ERROR",
        });
      });
  };
};

export const searchListTransaksiPPOB = (payload) => {
  return (dispatch) => {
    dispatch({
      type: "SET_LIST_TRANSAKSI_LOADING_BUTTON",
    });
    axios({
      method: "GET",
      url: payload,
      headers: {
        token: localStorage.getItem("token"),
      },
    })
      .then(({ data }) => {
        data.data.forEach((element, idx) => {
          element.idx = idx;
        });
        dispatch({
          type: "SET_LIST_TRANSAKSI_PPOB",
          payload: data.data,
          payload2: {
            totalCUSOPoint1: data.totalCUSOPoint1,
            totalCUSOPoint2: data.totalCUSOPoint2,
            totalRevShareCOOP: data.totalRevShareCOOP,
            totalRevShareCUSO: data.totalRevShareCUSO,
            totalTransaksi: data.totalTransaksi,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "SET_LIST_TRANSAKSI_ERROR",
        });
      });
  };
};

export const fetchListTransaksiBank = (payload) => {
  return (dispatch) => {
    dispatch({
      type: "SET_LIST_TRANSAKSI_LOADING",
    });
    axios({
      method: "GET",
      url: "ppobrev/topupbank",
      headers: {
        token: localStorage.getItem("token"),
      },
    })
      .then(({ data }) => {
        data.data.forEach((element, idx) => {
          element.idx = idx;
        });
        dispatch({
          type: "SET_LIST_TRANSAKSI_BANK",
          payload: data.data,
          payload2: {
            totalBiayaTransaksi: data.totalBiayaTransaksi,
            totalNominalCUSOPay: data.totalNominalCUSOPay,
            totalNominalTopUp: data.totalNominalTopUp,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "SET_LIST_TRANSAKSI_ERROR",
        });
      });
  };
};

export const searchListTransaksiBank = (payload) => {
  return (dispatch) => {
    dispatch({
      type: "SET_LIST_TRANSAKSI_LOADING_BUTTON",
    });
    axios({
      method: "GET",
      url: payload,
      headers: {
        token: localStorage.getItem("token"),
      },
    })
      .then(({ data }) => {
        data.data.forEach((element, idx) => {
          element.idx = idx;
        });
        dispatch({
          type: "SET_LIST_TRANSAKSI_BANK",
          payload: data.data,
          payload2: {
            totalBiayaTransaksi: data.totalBiayaTransaksi,
            totalNominalCUSOPay: data.totalNominalCUSOPay,
            totalNominalTopUp: data.totalNominalTopUp,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const fetchListTransaksiAll = () => {
  return (dispatch) => {
    dispatch({
      type: "SET_LIST_TRANSAKSI_LOADING",
    });
    axios({
      method: "GET",
      url: "ppobrev/alllist",
      headers: {
        token: localStorage.getItem("token"),
      },
    })
      .then(({ data }) => {
        data.data.forEach((element, idx) => {
          element.idx = idx;
        });
        dispatch({
          type: "SET_LIST_TRANSAKSI_ALL",
          payload: data.data,
          payload2: {
            totalCUSOPoint1: data.totalCUSOPoint1,
            totalCUSOPoint2: data.totalCUSOPoint2,
            totalRevShareCOOP: data.totalRevShareCOOP,
            totalRevShareCUSO: data.totalRevShareCUSO,
            totalTransaksi: data.totalTransaksi,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "SET_LIST_TRANSAKSI_ERROR",
        });
      });
  };
};

export const searchListTransaksiAll = (payload) => {
  return (dispatch) => {
    dispatch({
      type: "SET_LIST_TRANSAKSI_LOADING_BUTTON",
    });
    axios({
      method: "GET",
      url: payload,
      headers: {
        token: localStorage.getItem("token"),
      },
    })
      .then(({ data }) => {
        data.data.forEach((element, idx) => {
          element.idx = idx;
        });
        dispatch({
          type: "SET_LIST_TRANSAKSI_ALL",
          payload: data.data,
          payload2: {
            totalCUSOPoint1: data.totalCUSOPoint1,
            totalCUSOPoint2: data.totalCUSOPoint2,
            totalRevShareCOOP: data.totalRevShareCOOP,
            totalRevShareCUSO: data.totalRevShareCUSO,
            totalTransaksi: data.totalTransaksi,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: "SET_LIST_TRANSAKSI_ERROR",
        });
      });
  };
};

// SET DATA JENIS TRANSAKSI
export const setJenisTransaksi = () => {
  if (localStorage.getItem("jenis_tr_ppob") && localStorage.getItem("jenis_tr_viaBank")) {
    return (dispatch) => {
      dispatch({
        type: "SET_LIST_JENIS_TRANSAKSI",
        payloadPPOB: JSON.parse(localStorage.getItem("jenis_tr_ppob")),
        payloadViaBank: JSON.parse(localStorage.getItem("jenis_tr_viaBank")),
      });
    };
  };
};
