import { useRouteMatch, Route, Switch } from "react-router-dom";
import { TableDepositSetoran, TableMutasiSetoran } from "../components";

export default function DepositSetoranPage() {
	const { path } = useRouteMatch();
	return (
		<div>
			<Switch>
				<Route exact path={path}>
					<TableDepositSetoran />
				</Route>
				<Route exact path={`${path}/mutasi-setoran/:id`}>
					<TableMutasiSetoran />
				</Route>
			</Switch>
		</div>
	);
}
