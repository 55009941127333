import { Fragment, useEffect } from "react";
import { version }  from './autobuild_version'
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { PrivateRoute, PublicRoute, AdminRoute } from "./CustomRoutes";
import { Navbar, Sidebar, MobileSidebar } from "./components";
import {
  DashboardPage,
  DepositSetoranPage,
  DepositTopupPage,
  ListTransaksiPage,
  ListTransaksiPPOB,
  ListTransaksiViaBankPage,
  InfoProdukPage,
  UpdateInfoProdukPage,
  BeritaPage,
  ListBeritaPage,
  UpdateBeritaPage,
  ComponentPage,
  LoginPage,
  ResetPasswordPage,
  PushNotifikasiPage,
  UserManagementPage,
  RuangUsahaPage,
  BannerPage,
  ListBannerPage,
  ListRuangUsahaPage,
  UpdateRuangUsahaPage,
  UpdateBannerPage,
  ListAnggotaPage,
} from "./pages";

function App() {
  useEffect(() => {
    if(localStorage.getItem("version") != version){
      localStorage.clear();
      localStorage.setItem("version", version);
      window.location.reload()
    }
  }, []);

  return (
    <Router>
      <Switch>
        <PublicRoute exact path="/login">
          <LoginPage />
        </PublicRoute>
        <PublicRoute exact path="/reset-password">
          <ResetPasswordPage />
        </PublicRoute>
        <Fragment>
          <div className="flex h-screen bg-gray-50 dark:bg-gray-900">
            <Sidebar />
            <MobileSidebar />
            <div className="flex flex-col flex-1">
              <Navbar />
              <div className="overflow-y-auto">
                <PrivateRoute exact path="/">
                  <DashboardPage />
                </PrivateRoute>
                <PrivateRoute path="/deposit-topup">
                  <DepositTopupPage />
                </PrivateRoute>
                <PrivateRoute path="/deposit-setoran">
                  <DepositSetoranPage />
                </PrivateRoute>
                <PrivateRoute exact path="/list-transaksi-ppob">
                  <ListTransaksiPPOB />
                </PrivateRoute>
                <PrivateRoute path="/topup-via-bank">
                  <ListTransaksiViaBankPage />
                </PrivateRoute>
                <AdminRoute exact path="/list-transaksi">
                  <ListTransaksiPage />
                </AdminRoute>
                {/* <PrivateRoute exact path="/list-anggota">
                  <ListAnggotaPage />
                </PrivateRoute> */}
                <PrivateRoute path="/info-produk">
                  <InfoProdukPage />
                </PrivateRoute>
                <PrivateRoute path="/update-produk/:koperasi/:produk">
                  <UpdateInfoProdukPage />
                </PrivateRoute>
                <PrivateRoute path="/berita">
                  <BeritaPage />
                </PrivateRoute>
                <PrivateRoute path="/list-berita">
                  <ListBeritaPage />
                </PrivateRoute>
                <PrivateRoute path="/update-berita/:kode">
                  <UpdateBeritaPage />
                </PrivateRoute>
                <PrivateRoute path="/list-ruang-usaha">
                  <ListRuangUsahaPage />
                </PrivateRoute>
                <PrivateRoute path="/ruang-usaha">
                  <RuangUsahaPage />
                </PrivateRoute>
                <PrivateRoute path="/update-ruang-usaha/:kode">
                  <UpdateRuangUsahaPage />
                </PrivateRoute>
                <PrivateRoute path="/list-banner">
                  <ListBannerPage />
                </PrivateRoute>
                <PrivateRoute path="/banner">
                  <BannerPage />
                </PrivateRoute>
                <PrivateRoute path="/update-banner/:kode">
                  <UpdateBannerPage />
                </PrivateRoute>
                {/* <PrivateRoute path="/push-notifikasi">
                  <PushNotifikasiPage />
                </PrivateRoute> */}
                <PrivateRoute path="/component-page">
                  <ComponentPage />
                </PrivateRoute>
                <AdminRoute path="/user-management">
                  <UserManagementPage />
                </AdminRoute>
              </div>
            </div>
          </div>
        </Fragment>
      </Switch>
    </Router>
  );
}

export default App;
