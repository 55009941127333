import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory, useParams } from "react-router-dom";
import Row from "./Row";
import NominalCard from "../Card/NominalCard";
import TransaksiCard from "../Card/TransaksiCard";
import InputSearch from "../Form/InputSearch";
import ButtonSearch from "../Button/ButtonSearch";
import ButtonLoading from "../Button/ButtonLoading";
import { Loading, Error } from "../index";
import { fetchListTransaksiBank, searchListTransaksiBank, setJenisTransaksi } from "../../store/actions/listtransaksiAction";
import { fetchCU } from "../../store/actions/ruangUsahaAction";
import InputSimpleDatePicker from "../Form/InputSimpleDatePicker";
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export default function Table() {
  const query = new URLSearchParams(useLocation().search);
  const history = useHistory();
  const params = useParams();
  const dispatch = useDispatch();

  const {
    listTransaksiBank,
    listTransaksiBankTotal,
    jenisTransaksiViaBank,
    isLoadingButton,
    isLoading,
    isError,
  } = useSelector((state) => state.listtransaksi);
  let { listCU } = useSelector((state) => state.ruangUsaha);
  const [currentData, setCurrentData] = useState([]);
  const [paging, setPaging] = useState({
    startNumber: 0,
    limit: 10,
    canNext: true,
    canPrev: false,
    lastIdx: null,
  });
  const [input, setInput] = useState({
    // CU_Id: localStorage.getItem("CU_Id"),
    Kode_Transaksi: query.get("Kode_Transaksi") || "",
    Area: query.get("Area") || "",
    Nama_Anggota: query.get("Nama_Anggota") || "",
    Billers_Status: query.get("Billers_Status") || "",
    Nama_Jenis_Transaksi_Detail: query.get("Nama_Jenis_Transaksi_Detail") || "",
    min_date:
      query.get("min_date") ||
      new Date(new Date().setDate(new Date().getDate() - 0))
        .toISOString()
        .split("T")[0],
    max_date: query.get("max_date") || new Date().toISOString().split("T")[0],
  });

  // commented at CCS-33
  // useEffect(() => {
  //   let url = "ppobrev/topupbank";
  //   let urlQuery = "ppobrev/topupbank?";
  //   const arrQuery = [];
  //   if (+localStorage.getItem("id") === 0) {
  //     for (const key in input) {
  //       if (input[key].length > 0) {
  //         arrQuery.push(`${key}=${input[key]}`);
  //       }
  //     }
  //   } else if (+localStorage.getItem("id") === 1) {
  //     arrQuery.push(`CU_Id=${localStorage.getItem("CU_Id")}`);
  //     for (const key in input) {
  //       if (input[key].length > 0) {
  //         arrQuery.push(`${key}=${input[key]}`);
  //       }
  //     }
  //   }
  //   urlQuery += arrQuery.join("&");
  //   if (urlQuery[urlQuery.length - 1] !== "?") {
  //     url = urlQuery;
  //   }
  //   if (arrQuery.length) {
  //     dispatch(searchListTransaksiBank(url));
  //   } else {
  //     dispatch(fetchListTransaksiBank());
  //   }
  // }, [params]);

  // FETCH DATA CU WHEN OPENING PAGE && SET JENIS TRANSAKSI
  useEffect(() => {
    dispatch(fetchCU());
    dispatch(setJenisTransaksi());
  }, []);

  useEffect(() => {
    const expired = +localStorage.getItem("expired")
    const now = new Date()

    if (localStorage.getItem("expired") && expired < now.getTime()) {
      localStorage.clear();
      window.location.reload()
    }

    if (listTransaksiBank.length) {
      // pagination
      if (10 >= listTransaksiBank[listTransaksiBank.length - 1].idx + 1) {
        setPaging({
          ...paging,
          startNumber: 0,
          limit: 10,
          canNext: false,
          canPrev: false,
          lastIdx: listTransaksiBank[listTransaksiBank.length - 1].idx,
        });
      } else {
        setPaging({
          ...paging,
          startNumber: 0,
          limit: 10,
          canNext: true,
          canPrev: false,
          lastIdx: listTransaksiBank[listTransaksiBank.length - 1].idx,
        });
      }
      const tempData = [];
      for (let i = 0; i < 10 && i < listTransaksiBank.length; i++) {
        tempData.push(listTransaksiBank[i]);
      }
      setCurrentData(tempData);
    } else {
      setCurrentData([]);
      setPaging({
        ...paging,
        startNumber: 0,
        limit: 10,
        canNext: false,
        canPrev: false,
        lastIdx: 0,
      });
    }
  }, [listTransaksiBank]);

  // CONVERT DATA TO CSV
  const convertCSV = (items) => {

    // FILTER JENIS TRX
    const filterJenisTrx = (value) => {
      if (value === 0) {
        return "Pending";
      } else if (value === 1) {
        return "Berhasil";
      } else if (value === 2) {
        return "Gagal";
      };
    };

    // FILET CHECK DATA
    const checkData = (value) => {
      if (value) {
        const result = value.toString().replaceAll(",", ".").replaceAll("\r\n", "");;
        return result;
      } else {
        return "-";
      };
    };

    const mappingData = items.map((e, idx) => ({
      No: idx + 1,
      "Kode Transaksi": checkData(e.Kode_Transaksi),
      "ID Anggota": checkData(e.ID_Anggota),
      "Nama Anggota": checkData(e.Nama_Anggota),
      "Jenis Transaksi": checkData(e.Nama_Jenis_Transaksi_Detail),
      "Status Transaksi": filterJenisTrx(e.Billers_Status),
      "Tanggal Transaksi": checkData(e.Tgl_Transaksi),
      "Jam Transaksi": checkData(e.Jam_Transaksi),
      "Nominal TOPUP": checkData(e.nominalTopUp),
      "Nominal CUSO PAY": checkData(e.Nominal_CUSO_Pay),
      "Biaya Transaksi": checkData(e.Nominal_Biaya_Transaksi),
      "Keterangan": checkData(e.Keterangan),
    }));

    let csv = '';
    let header = Object.keys(mappingData[0]).join(',');
    let values = mappingData.map(o => Object.values(o).join(',')).join('\n');
    csv += header + '\n' + values;

    const data = new Blob([csv], { type: 'text/csv' });
    const csvURL = window.URL.createObjectURL(data);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', 'List Transaksi Via Bank.csv');
    tempLink.click();
  };

  const next = () => {
    if (paging.limit + 10 >= listTransaksiBank.length) {
      setPaging({
        ...paging,
        startNumber: paging.startNumber + 10,
        limit: paging.limit + 10,
        canNext: false,
        canPrev: true,
      });
    } else {
      setPaging({
        ...paging,
        startNumber: paging.startNumber + 10,
        limit: paging.limit + 10,
        canNext: true,
        canPrev: true,
      });
    }

    const tempData = [];
    if (paging.startNumber >= -10) {
      for (
        let i = paging.startNumber + 10;
        i < paging.limit + 10 && i < listTransaksiBank.length;
        i++
      ) {
        tempData.push(listTransaksiBank[i]);
      }
      setCurrentData(tempData);
    } else {
      setCurrentData([]);
    }
  };

  const prev = () => {
    if (paging.startNumber - 10 <= 0) {
      setPaging({
        ...paging,
        startNumber: paging.startNumber - 10,
        limit: paging.limit - 10,
        canNext: true,
        canPrev: false,
      });
    } else {
      setPaging({
        ...paging,
        startNumber: paging.startNumber - 10,
        limit: paging.limit - 10,
        canNext: true,
        canPrev: true,
      });
    }

    const tempData = [];
    if (
      paging.startNumber >= 10 &&
      paging.startNumber < listTransaksiBank.length
    ) {
      for (let i = paging.startNumber - 10; i < paging.limit - 10; i++) {
        tempData.push(listTransaksiBank[i]);
      }
      setCurrentData(tempData);
    } else if (
      paging.startNumber >= 10 &&
      paging.lastIdx + 1 === listTransaksiBank.length
    ) {
      for (let i = paging.startNumber - 10; i < paging.lastIdx + 1; i++) {
        tempData.push(listTransaksiBank[i]);
      }
      setCurrentData(tempData);
    } else {
      setCurrentData([]);
    }
  };

  const onDateChange = (name, value) => {
    setInput({
      ...input,
      [name]: value,
    });
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setInput({
      ...input,
      [name]: value,
    });
  };

  const searchHandler = (e) => {
    e.preventDefault();
    let params = new URLSearchParams();

    // search logic from hooks
    // --------------------------------------------------------------
    let url = "ppobrev/topupbank";
    let urlQuery = "ppobrev/topupbank?";
    const arrQuery = [];
    if (+localStorage.getItem("id") === 0) {
      for (const key in input) {
        if (input[key].length > 0) {
          arrQuery.push(`${key}=${input[key]}`);
        }
      }
    } else if (+localStorage.getItem("id") !== 0) {
      arrQuery.push(`CU_Id=${localStorage.getItem("CU_Id")}`);
      for (const key in input) {
        if (input[key].length > 0) {
          arrQuery.push(`${key}=${input[key]}`);
        }
      }
    }
    urlQuery += arrQuery.join("&");
    if (urlQuery[urlQuery.length - 1] !== "?") {
      url = urlQuery;
    }
    if (arrQuery.length) {
      dispatch(searchListTransaksiBank(url));
    } else {
      dispatch(fetchListTransaksiBank());
    }
    //------------------------------------------------------------


    if (+localStorage.getItem("id") !== 0) {
      params.append("CU_Id", localStorage.getItem("CU_Id"));
    }
    for (const i in input) {
      if (input[i].length > 0) {
        params.append(i, input[i]);
      }
    }
    history.push({ search: params.toString() });
  };

  if (isLoading)
    return (
      <>
        <Loading />
      </>
    );

  if (isError)
    return (
      <>
        <Error />
      </>
    );

  return (
    <main className="pb-10">
      <div className="container grid px-6 mx-auto">
        <h2 className="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
          Topup Via Bank
        </h2>
        <div className="grid md:grid-cols-4 grid-cols gap-4">
          <TransaksiCard
            title="Total Transaksi"
            transaksi={listTransaksiBank.length}
          />
          <NominalCard
            title="Total Biaya Transaksi"
            nominal={listTransaksiBankTotal.totalBiayaTransaksi}
          />
          <NominalCard
            title="Total CUSO Pay"
            nominal={listTransaksiBankTotal.totalNominalCUSOPay}
          />
          <NominalCard
            title="	Total TopUp"
            nominal={listTransaksiBankTotal.totalNominalTopUp}
          />
        </div>
        <div className="flex flex-col md:flex-row md:justify-between md:gap-8 p-4 mb-2">
          <div className="w-full">
            <InputSearch
              label="Kode Transaksi"
              name="Kode_Transaksi"
              value={input.Kode_Transaksi}
              onChange={(e) => {
                onChangeHandler(e);
              }}
            />
            <InputSearch
              label="ID Anggota"
              name="ID_Anggota"
              value={input.ID_Anggota}
              onChange={(e) => {
                onChangeHandler(e);
              }}
            />
            <InputSearch
              label="Nama"
              name="Nama_Anggota"
              value={input.Nama_Anggota}
              onChange={(e) => {
                onChangeHandler(e);
              }}
            />
            {localStorage.getItem("CU_Id") == "null" && localStorage.getItem("name") === "cuso_admin" && localStorage.getItem("id") == "0" ? (
              <span className="flex justify-between items-center my-1 md:text-lg">
                <label className="w-1/2 text-sm text-gray-700 dark:text-gray-400">
                  Nama Koperasi
                </label>
                <select
                  name="CU_Id"
                  value={input.CU_Id}
                  onChange={(e) => {
                    onChangeHandler(e);
                  }}
                  className="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-select focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
                  list="CU_Id"
                  placeholder="Pilih"
                >
                  <option selected value={""}>Semua Koperasi</option>
                  {listCU?.map((e, idx) => (
                    <option value={e.CU_Id} key={idx}>{e.CU_Id} - {e.Nama_CU}</option>
                  ))}
                </select>
              </span>
            ) : null}
          </div>
          <div className="w-full flex flex-col justify-between">
            <span className="flex justify-between items-center my-1 md:text-lg">
              <label className="w-1/2 text-sm text-gray-700 dark:text-gray-400">
                Status Transaksi
              </label>
              <select
                className="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-select focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
                name="Billers_Status"
                value={input.Billers_Status}
                onChange={(e) => {
                  onChangeHandler(e);
                }}
              >
                <option value="" defaultValue></option>
                <option value="0">Pending</option>
                <option value="1">Berhasil</option>
                <option value="2">Gagal</option>
              </select>
            </span>
            <span className="flex justify-between items-center my-1 md:text-lg">
              <label className="w-1/2 text-sm text-gray-700 dark:text-gray-400">
                Jenis Transaksi
              </label>
              <select
                name="Nama_Jenis_Transaksi_Detail"
                value={input.Nama_Jenis_Transaksi_Detail}
                onChange={(e) => {
                  onChangeHandler(e);
                }}
                className="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-select focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
                list="jenis-transaksi"
                placeholder="Pilih Jenis Transaksi"
              >
                <option selected value={""}>Semua Jenis Transaksi</option>
                {jenisTransaksiViaBank?.map((e, idx) => (
                  <option value={e} key={idx}>{e}</option>
                ))}
              </select>
              {/* DATA LIST TEMPORARY NOT USED*/}
              {/* <datalist id="jenis-transaksi">
                <option value="" defaultValue />
                {jenisTransaksiViaBank.map((el) => {
                  return <option value={el} />;
                })}
              </datalist> */}
            </span>
            <span className="flex justify-between items-center my-1 md:text-lg">
              <label className="w-1/2 text-sm text-gray-700 dark:text-gray-400">
                Tgl Transaksi Awal
              </label>
              <InputSimpleDatePicker
                handleChange={(date) => onDateChange("min_date", date)}
                value={input.min_date ? new Date(input.min_date) : ""}
              />
            </span>
            <span className="flex justify-between items-center my-1 md:text-lg">
              <label className="w-1/2 text-sm text-gray-700 dark:text-gray-400">
                Tgl Transaksi Akhir
              </label>
              <InputSimpleDatePicker
                handleChange={(date) => onDateChange("max_date", date)}
                value={input.max_date ? new Date(input.max_date) : ""}
              />
            </span>
            {isLoadingButton ? (
              <ButtonLoading />
            ) : (
              <ButtonSearch onClick={(e) => searchHandler(e)} />
            )}
          </div>
        </div>
        <div className="w-full overflow-hidden rounded-lg shadow-md">
          <div className="sticky top-0 w-full overflow-x-auto h-85vh">
            <table className="relative w-full whitespace-no-wrap">
              <thead>
                <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800">
                  <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800">
                    No
                  </th>
                  <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800">
                    Kode Transaksi
                  </th>
                  <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800">
                    ID Anggota
                  </th>
                  {/* <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800">
										No BA
									</th> */}
                  <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800">
                    Nama Anggota
                  </th>
                  <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800">
                    Jenis Transaksi
                  </th>
                  <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800">
                    Status Transaksi
                  </th>
                  <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800">
                    Tanggal Transaksi
                  </th>
                  <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800">
                    Jam Transaksi
                  </th>
                  <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800">
                    Nominal TopUp
                  </th>
                  <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800">
                    Nominal CUSO Pay
                  </th>
                  <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800">
                    Biaya Transaksi
                  </th>
                  {/* <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800">
                    Saldo
                  </th> */}
                  <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800">
                    Keterangan
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                {currentData.map((d, index) => {
                  return (
                    <Row
                      key={index}
                      data={d}
                      idx={index + paging.startNumber}
                    />
                  );
                })}
              </tbody>
            </table>
          </div>
          {/* Pagination if needed */}
          <div className="flex justify-between flex-wrap px-4 py-3 text-xs font-semibold tracking-wide text-gray-500 uppercase border-t dark:border-gray-700 bg-gray-50 sm:grid-cols-9 dark:text-gray-400 dark:bg-gray-800">
            <span className="flex items-center">
              <span>
                Showing{" "}
                {listTransaksiBank.length > 0
                  ? `${paging.startNumber + 1}-${paging.startNumber + currentData.length
                  } of ${listTransaksiBank.length}`
                  : "0-0 of 0"}
              </span>
              <span>
                {listTransaksiBank.length && listTransaksiBank.length > 10000 ? (
                  <button className="flex w-full items-center justify-center px-4 py-2 text-sm font-medium leading-5 text-green-600 hover:text-green-700 focus:outline-none focus:shadow-outline-green"
                    onClick={() => convertCSV(listTransaksiBank)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                      />
                    </svg>
                    <span className="ml-2">Download as Excel</span>
                  </button>
                ) : (
                  <ExcelFile
                    element={
                      <button className="flex w-full items-center justify-center px-4 py-2 text-sm font-medium leading-5 text-green-600 hover:text-green-700 focus:outline-none focus:shadow-outline-green">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                          />
                        </svg>
                        <span className="ml-2">Download as Excel</span>
                      </button>
                    }
                    filename="List Transaksi Via Bank"
                  >
                    <ExcelSheet
                      data={listTransaksiBank}
                      name={"List Transaksi Via Bank"}
                    >
                      <ExcelColumn label="No" value={(col) => col.idx + 1} />
                      <ExcelColumn
                        label="Kode Transaksi"
                        value="Kode_Transaksi"
                      />
                      <ExcelColumn label="ID Anggota" value="ID_Anggota" />
                      <ExcelColumn label="Nama Anggota" value="Nama_Anggota" />
                      <ExcelColumn
                        label="Jenis Transaksi"
                        value="Nama_Jenis_Transaksi_Detail"
                      />
                      <ExcelColumn
                        label="Tanggal Transaksi"
                        value="Tgl_Transaksi"
                      />
                      <ExcelColumn
                        label="Jam Transaksi"
                        value="Jam_Transaksi"
                      />
                      <ExcelColumn label="Nominal Topup" value="nominalTopUp" />
                      <ExcelColumn
                        label="Nominal Cuso Pay"
                        value="Nominal_CUSO_Pay"
                      />
                      <ExcelColumn
                        label="Nominal Biaya Transaksi"
                        value="Nominal_Biaya_Transaksi"
                      />
                      {/* <ExcelColumn label="Saldo" value="Saldo_Anggota" />
                    <ExcelColumn label="Keterangan" value="Keterangan" /> */}
                    </ExcelSheet>
                  </ExcelFile>
                )}
              </span>
            </span>
            <span className="col-span-2"></span>
            <span className="flex col-span-4 mt-2 sm:mt-auto justify-end">
              <nav aria-label="Table navigation">
                <ul className="inline-flex items-center py-2">
                  <li className="mx-2">
                    <button
                      className={`px-3 py-1 rounded-md rounded-l-lg focus:outline-none focus:shadow-outline-purple ${paging.canPrev
                        ? "bg-purple-600 hover:bg-purple-800"
                        : "bg-purple-300 cursor-default"
                        } text-white`}
                      disabled={paging.canPrev ? false : true}
                      onClick={prev}
                    >
                      PREV
                    </button>
                  </li>
                  <li>
                    <button
                      className={`px-3 py-1 rounded-md rounded-l-lg focus:outline-none focus:shadow-outline-purple ${paging.canNext
                        ? "bg-purple-600 hover:bg-purple-800"
                        : "bg-purple-300 cursor-default"
                        } text-white`}
                      disabled={paging.canNext ? false : true}
                      onClick={next}
                    >
                      NEXT
                    </button>
                  </li>
                </ul>
              </nav>
            </span>
          </div>
        </div >
      </div >
    </main >
  );
}
