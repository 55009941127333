const initialState = {
  listAnggota: [],
  isLoading: false,
  isLoadingButton: false,
  isError: false,
};

function anggotaReducer(state = initialState, action) {
  switch (action.type) {
    case "SET_LIST_ANGGOTA":
      return {
        ...state,
        listAnggota: action.payload,
        isLoading: false,
        isLoadingButton: false,
        isError: false,
      };
    case "SET_LIST_ANGGOTA_LOADING":
      return {
        ...state,
        isLoading: true,
        isError: false,
      };
    case "SET_BUTTON_LIST_ANGGOTA_LOADING":
      return {
        ...state,
        isLoadingButton: true,
        isError: false,
      };
    case "SET_LIST_ANGGOTA_ERROR":
      return {
        ...state,
        isLoading: false,
        isLoadingButton: false,
        isError: true,
      };
    default:
      return state;
  }
}

export default anggotaReducer;
