const initialState = {
	isLogin: false,
	isSideMenuOpen: false,
	isLoading: false,
	tipeUser: 1,
	cuId: "",
};

function userReducer(state = initialState, action) {
	switch (action.type) {
		case "SET_LOGIN_STATUS":
			return {
				...state,
				isLogin: action.payload.isLogin,
				tipeUser: action.payload.tipeUser,
				cuId: action.payload.cuId,
				isLoading: false,
			};
		case "SET_LOGIN_LOADING":
			return {
				...state,
				isLoading: true,
			};
		case "RESET_LOGIN_LOADING":
			return {
				...state,
				isLoading: false,
			};
		case "SET_MENU_BAR_OPEN":
			return {
				...state,
				isSideMenuOpen: action.payload,
			};
		default:
			return state;
	}
}

export default userReducer;
