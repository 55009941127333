export default function TransaksiCard({ title, transaksi }) {
	return (
		<div className="flex items-center h-20 p-4 bg-white rounded-lg shadow dark:bg-gray-800">
			<div className="p-3 mr-4 text-red-500 bg-green-100 rounded-full dark:text-green-100 dark:bg-red-500">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					className="h-5 w-5"
					viewBox="0 0 20 20"
					fill="currentColor"
				>
					<path
						fillRule="evenodd"
						d="M4 4a2 2 0 012-2h8a2 2 0 012 2v12a1 1 0 110 2h-3a1 1 0 01-1-1v-2a1 1 0 00-1-1H9a1 1 0 00-1 1v2a1 1 0 01-1 1H4a1 1 0 110-2V4zm3 1h2v2H7V5zm2 4H7v2h2V9zm2-4h2v2h-2V5zm2 4h-2v2h2V9z"
						clipRule="evenodd"
					/>
				</svg>
			</div>
			<div>
				<p className="mb-2 text-sm font-medium text-gray-600 dark:text-gray-400">
					{title}
				</p>
				<p className="text-lg font-semibold text-gray-700 dark:text-gray-200">
					{transaksi}
				</p>
			</div>
		</div>
	);
}
