import { useState, useRef, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import Swal from "sweetalert2"
import { fetchCU, updateBanner, fetchCurrentBanner } from "../../store/actions/ruangUsahaAction";

export default function UpdateBanner() {
  let { kode } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  let { listCU, currentBanner } = useSelector((state) => state.ruangUsaha);

  useEffect(() => {
    const expired = +localStorage.getItem("expired")
    const now = new Date()

    if(localStorage.getItem("expired") && expired < now.getTime()){
        localStorage.clear();
        window.location.reload()
    }
    
    dispatch(fetchCurrentBanner(kode));
    dispatch(fetchCU());
  }, [dispatch]);

  const [image, setImage] = useState('')
  const [imagePreview, setImagePreview] = useState('')
  const [imageUploaded, setImageUploaded] = useState(false)
  const [nama, setNama] = useState('')
  const [infoSingkat, setInfoSingkat] = useState('')
  const [category, setCategory] = useState(null)
  const [deskripsi, setDeskripsi] = useState("")
  const [harga, setHarga] = useState("")
  const [noWhatsapp, setNoWhatsapp] = useState(null)
  const [halamanUtama, setHalamanUtama] = useState(null)
  const [tipePenjualan, setTipePenjualan] = useState(null)
  const [koperasi, setKoperasi] = useState(null)

  //alert
  function alert(text){
    Swal.fire({
      title: "Oops!",
      text: text,
      icon: "error",
      showConfirmButton: true,
      position: "center",
      allowOutsideClick: false,
    });
  }

  //Foto Produk
  function handleImageChange(e){
    if(e.target.files && e.target.files[0] && e.target.files[0].size < 300000){
      setImage(e.target.files[0])
      let reader = new FileReader()

      reader.onload = function(e){
        setImagePreview(e.target.result)
        setImageUploaded(true)
      }

      reader.readAsDataURL(e.target.files[0])
    }else if(e.target.files && e.target.files[0] && e.target.files[0].size >= 300000){
      Swal.fire({
        title: "Oops!",
        text: "Ukuran gambar terlalu besar, silahkan pilih gambar lain",
        icon: "error",
        showConfirmButton: true,
        position: "center",
        allowOutsideClick: false,
      });
    }
  }

  //Nama Produk
  function handleNamaChange(e){
    if(e.target.value.length){
      setNama(e.target.value)
    }else{
      setNama("")
    }
  }

  //InfoSingkat Produk
  function handleInfoSingkatChange(e){
    if(e.target.value.length){
      setInfoSingkat(e.target.value)
    }else{
      setInfoSingkat("")
    }
  }

  //Deskripsi Produk
  const handleEditorChange = (content) => {
    setDeskripsi(content)
  };

  //CU_Id
  function handleKoperasiChange(e){
    if(e.target.value){
      setKoperasi(e.target.value)
    }else{
      setKoperasi(null)
    }
  }

  //no Whatsapp
  function handleNoWhatsappChange(e){
    if(e.target.value){
      setNoWhatsapp(e.target.value)
    }else{
      setNoWhatsapp("")
    }
  }

  
  //submit
  function handleSubmit(e){
    e.preventDefault()

    let html = `<div style="text-align:left;font-family:sans">`

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    const separator = '-'
    const tanggal = `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date<10?`0${date}`:`${date}`}`

    const fd = new FormData()
    fd.append('Status_Banner', 0)
    fd.append('Tanggal', tanggal)
    fd.append('Kode_Kategori', '005')
    fd.append('Status', 0)
    fd.append('Tipe_Penjualan', 1)

    if(localStorage.getItem('CU_Id') === 'null' && koperasi){
        fd.append('CU_Id', koperasi)
        html += `<p>Koperasi : ${listCU.find(item => item.CU_Id == koperasi).Nama_CU}</p>`
    }else if(localStorage.getItem('CU_Id') === 'null' && !koperasi){
        fd.append('CU_Id', currentBanner.CU_Id)
        html += `<p>Koperasi : ${listCU.find(item => item.CU_Id == currentBanner.CU_Id).Nama_CU}</p>`
    }else{
        fd.append('CU_Id', localStorage.getItem('CU_Id'))
        html += `<p>Koperasi : ${listCU.find(item => item.CU_Id == localStorage.getItem('CU_Id')).Nama_CU}</p>`
    }
        
    if(image){
      fd.append('Foto', image)
    }else{
      fd.append('Foto_URL', currentBanner.Foto_URL)
    }
        
    if(nama){
      fd.append('Judul', nama)
      html += `<p>Nama Produk : ${nama}</p>`
    }else{
      fd.append('Judul', currentBanner.Judul)
      html += `<p>Nama Produk : ${currentBanner.Judul}</p>`
    }

    if(infoSingkat){
        fd.append('Info_Singkat', infoSingkat)
        html += `<p>Info Singkat : ${infoSingkat}</p>`
    }else{
        fd.append('Info_Singkat', currentBanner.Info_Singkat)
        html += `<p>Info Singkat : ${currentBanner.Info_Singkat}</p>`
    }

    if(deskripsi){
      fd.append('Informasi_Detail', deskripsi)
      html += `<p>Deskripsi : ${deskripsi}</p>`
    }else{
      fd.append('Informasi_Detail', currentBanner.Informasi_Detail)
      html += `<p>Deskripsi : ${currentBanner.Informasi_Detail}</p>`
    }

    if(noWhatsapp){
        fd.append('No_Hp_Whatsapp', noWhatsapp)
        html += `<p>No Whatsapp : ${noWhatsapp}</p>`
    }else{
        fd.append('No_Hp_Whatsapp', currentBanner.No_HP_Whatsapp)
        html += `<p>No Whatsapp : ${currentBanner.No_HP_Whatsapp}</p>`
    }

    Swal.fire({
          title: 'Apakah Anda Sudah Yakin?',
          html: html,
          imageUrl: imagePreview || currentBanner.Foto_URL,
          imageWidth: 400,
          imageHeight: 200,
          showCancelButton: true,
          confirmButtonText: 'Simpan',
          denyButtonText: `Batal`,
        }).then((result) => {
          if (result.isConfirmed){
            dispatch(updateBanner(fd, kode))            
            history.push('/list-banner')
          }else if (result.isDenied) {
            Swal.fire('Changes are not saved', '', 'info')
          }
        })
  }
  
  return (
    <div>
        {currentBanner ? 
        <main className="h-full pb-16 overflow-y-auto">
			<div className="container px-6 mx-auto grid">
				<div className="px-4 py-3 mt-8 mb-8 bg-white rounded-lg shadow-md dark:bg-gray-800">

                    {/** Upload Form*/}
                    <form className="w-full" onSubmit={handleSubmit}>
                        {/** Judul Form */}
                        <div className="flex flex-wrap w-full mb-10">
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <h1 className="font-sans font-bold text-2xl">Edit Banner</h1>
                        </div>
                        </div>

                        {/** Image */}
                        <div className="flex flex-wrap w-full mb-10">
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <h1 className="font-['Open_Sans'] font-bold text-xl">Foto Produk</h1>
                            <p className="text-sm font-['Open_Sans']">
                                Pilih foto produk atau tarik dan letakkan
                                foto di sini.
                            </p>
                            <p className="text-sm">
                                Format gambar .jpg .jpeg .png dan
                                ukuran maximum 300kb.
                            </p>
                        </div>

                        {
                            imageUploaded ? 
                            <div className="w-full md:w-2/3 px-3">
                                <img className="w-full px-3 h-96" src={imagePreview}/>
                            <div className="flex items-center mt-5 justify-center w-full">
                                <label
                                    className="flex flex-col w-full h-100 border-4 border-blue-200 border-dashed hover:bg-gray-100 hover:border-gray-300">
                                    <div className="flex flex-col items-center justify-center pt-7">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-gray-400 group-hover:text-gray-600"
                                            fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                                        </svg>
                                        <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                            Update Photo</p>
                                    </div>
                                    <input id="upload-image-produk" type="file" accept=".jpg,.png,.png,.jpeg" className="opacity-0" onChange={handleImageChange}/>
                                </label>
                            </div>
                            </div>
                            : 
                            <div className="w-full md:w-2/3 px-3">
                                <img className="w-full px-3 h-96" src={currentBanner.Foto_URL}/>
                                <div className="flex mt-5 items-center justify-center w-full">
                                <label
                                    className="flex flex-col w-full h-100 border-4 border-blue-200 border-dashed hover:bg-gray-100 hover:border-gray-300">
                                    <div className="flex flex-col items-center justify-center pt-7">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-gray-400 group-hover:text-gray-600"
                                            fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                                        </svg>
                                        <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                            Update Photo</p>
                                    </div>
                                    <input id="upload-image-produk" type="file" accept=".jpg,.png,.png,.jpeg" className="opacity-0" onChange={handleImageChange}/>
                                </label>
                            </div>
                            </div>
                        } 
                        </div>

                        {/** Divider 1*/}
                        <div className="flex flex-wrap w-full mb-6 border-dashed border-t-2 border-gray-200">
                        <div className="w-full md:w-1/3 px-3 mt-10 mb-6 md:mb-0">
                            <h1 className="font-sans font-bold text-2xl">Informasi Produk</h1>
                        </div>
                        </div>

                        {/** Nama Produk */}
                        <div className="flex flex-wrap w-full mb-10">
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <h1 className="font-['Open_Sans'] font-bold text-xl mb-2">Nama Produk</h1>
                            <p className="text-sm font-['Open_Sans']">
                            Cantumkan nama produk yang deskriptif agar
                            semakin menarik dan mudah ditemukan
                            oleh pembeli, terdiri dari jenis produk,
                            merek, dan keterangan seperti warna,
                            bahan, atau tipe.
                            </p>
                        </div>
                        <div className="w-full md:w-2/3 px-3">
                            <label className="block text-sm mt-10">
                            <input
                                className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                                placeholder={currentBanner.Judul}
                                onChange={handleNamaChange}
                            />
                            </label>
                        </div>  
                        </div>

                        {/** Info Singkat */}
                        <div className="flex flex-wrap w-full mb-10">
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <h1 className="font-['Open_Sans'] font-bold text-xl mb-2">Info Singkat</h1>
                            <p className="text-sm font-['Open_Sans']">
                            Cantumkan info produk secara singkat agar pembeli
                            mengetahui informasi dasar tentang produk ini.
                            </p>
                        </div>
                        <div className="w-full md:w-2/3 px-3 mt-10">
                            <label className="block text-sm">
                            <input
                                className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                                placeholder={currentBanner.Info_Singkat}
                                onChange={handleInfoSingkatChange}
                            />
                            </label>
                        </div>  
                        </div>
                        
                        {/** Deskripsi Produk */}
                        <div className="flex flex-wrap w-full mb-10">
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <h1 className="font-['Open_Sans'] font-bold text-xl mb-2">Deskripsi Produk</h1>
                            <p className="text-sm font-['Open_Sans']">
                            Pastikan deskripsi produk memuat
                            spesifikasi, ukuran, bahan, masa berlaku,
                            dan lainnya. Semakin detail, semakin
                            berguna bagi pembeli, cantumkan max. 2500 karakter
                            agar pembeli semakin
                            mudah mengerti dan menemukan
                            produk anda
                            </p>
                        </div>
                        <div className="w-full md:w-2/3 px-3 mt-6">
                            <label className="block mt-4 text-sm">
                            <Editor
                                apiKey={window.env.REACT_APP_TINY_MCE_API_KEY}
                                initialValue={currentBanner.Informasi_Detail}
                                init={{
                                height: 600,
                                menubar: false,
                                plugins: [
                                    "advlist autolink lists link image charmap print preview anchor",
                                    "searchreplace visualblocks code fullscreen",
                                    "insertdatetime media table paste code help wordcount",
                                ],
                                toolbar:
                                    "undo redo | formatselect | bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent link | removeformat | help",
                                }}
                                onEditorChange={handleEditorChange}
                            />
                            </label>
                        </div>  
                        </div>

                        {/** Divider 2*/}
                        <div className="flex flex-wrap w-full mb-8  border-dashed border-t-2 border-gray-200">
                        <div className="w-full md:w-1/3 px-3 mt-10 mb-6 md:mb-0">
                            <h1 className="font-sans font-bold text-2xl">Harga & Pengelolaan Produk</h1>
                        </div>
                        </div>


                        {/** Nama Koperasi (untuk admin) */}
                        {
                        localStorage.getItem('CU_Id') === 'null' ? <div>
                            {/** CU_Id */}
                            <div className="flex flex-wrap w-full mb-10">
                            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                                <h1 className="font-['Open_Sans'] font-bold text-xl mb-2">Koperasi</h1>
                                <p className="text-sm font-['Open_Sans']">
                                Silahkan Pilih Koperasi
                                </p>
                            </div>
                            <div className="w-full md:w-2/3 px-3 mt-5">
                                <select onChange={handleKoperasiChange} className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                                    {
                                        !currentBanner.CU_Id ? <option selected disabled >Pilih Koperasi</option>
                                        : <option disabled >Pilih Koperasi</option>
                                        
                                    }
                                    {listCU.map((data, idx) => {
                                        if(data.CU_Id == currentBanner.CU_Id) {
                                            return (
                                                <option value={data.CU_Id} key={idx} selected>{data.Nama_CU}</option>
                                            )
                                        }else{
                                            return (
                                                <option value={data.CU_Id} key={idx}>{data.Nama_CU}</option>
                                            )
                                        }
                                    })}                    
                                </select>
                                
                            </div>  
                            </div>
                        </div>:<div>
                        </div>
                        }

                        {/** No HP/Whatsapp */}
                        <div className="flex flex-wrap w-full mb-10">
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            <h1 className="font-['Open_Sans'] font-bold text-xl mb-2">Nomor HP/Whatsapp</h1>
                            <p className="text-sm font-['Open_Sans']">
                            Cantumkan nomor handphone atau whatsapp yang dapat dihubungi agar pembeli mudah untuk menghubungi penjual.
                            </p>
                        </div>
                        <div className="w-full md:w-2/3 px-3 mt-10">
                            <label className="block text-sm">
                            <input
                                className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                                placeholder={currentBanner.No_HP_Whatsapp}
                                onChange={handleNoWhatsappChange}
                            />
                            </label>
                        </div>  
                        </div>

                        {/** Submit */}
                        <div className="flex flex-wrap justify-center w-full mb-10">
                        <button
                            type="submit"
                            className="w-full md:w-1/3 flex justify-center py-2 my-1 rounded-lg text-white items-center text-sm bg-green-700 focus:outline-none"
                        >
                            Simpan
                        </button>
                        </div>
                    </form>
				</div>
			</div>
		</main>
        :
         <main>
             <div>Harap Tunggu ....</div>
         </main>
        }
      
    </div>
  );
}
