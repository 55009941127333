export default function ButtonSearch({ onClick }) {
	return (
		<button
			onClick={(e) => onClick(e)}
			className="w-full py-2 my-1 rounded-lg text-white items-center text-sm bg-purple-600 hover:bg-purple-800 focus:outline-none"
		>
			Search
		</button>
	);
}
