import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "../index";
// import ButtonLoading from "../Button/ButtonLoading";
import { fetchCUId } from "../../store/actions/userManagementAction";
import axios from "../../config/axiosinstance";

export default function AddUser() {
  const { listKoperasi, isLoading } = useSelector(
    (state) => state.userManagement
  );
  const [input, setInput] = useState({
    name: "",
    username: "",
    tipe_user: 1,
    email: "",
    cu_id: "",
    password: "",
  });
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    const expired = +localStorage.getItem("expired")
    const now = new Date()

    if (localStorage.getItem("expired") && expired < now.getTime()) {
      localStorage.clear();
      window.location.reload()
    }

    dispatch(fetchCUId());
  }, [dispatch]);

  const submitAddUser = (e) => {
    e.preventDefault();
    // dispatch(addUser(input));
    axios({
      url: "muser/register",
      method: "POST",
      headers: {
        token: localStorage.getItem("token"),
      },
      data: input,
    })
      .then(({ data }) => {
        history.push("/user-management");
      })
      .catch(({ response }) => {
        console.log(response);
      });
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setInput({
      ...input,
      [name]: value,
    });
  };
  if (isLoading)
    return (
      <>
        <Loading />
      </>
    );
  return (
    <main className="pb-10">
      <div className="container p-4 px-10 mx-auto">
        <div className="w-full bg-white dark:bg-gray-700 p-6 rounded-lg shadow-lg">
          <h1 className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">
            Tambahkan User Baru
          </h1>
          <form
            onSubmit={(e) => {
              submitAddUser(e);
            }}
          >
            <label className="block mt-2 text-sm">
              <span className="text-gray-700 dark:text-gray-400">Name</span>
              <input
                className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                name="name"
                value={input.name}
                onChange={(e) => onChangeHandler(e)}
                autoComplete="false"
                required
              />
            </label>
            <label className="block mt-2 text-sm">
              <span className="text-gray-700 dark:text-gray-400">Username</span>
              <input
                className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                name="username"
                value={input.username}
                onChange={(e) => onChangeHandler(e)}
                required
              />
            </label>
            <label className="block mt-2 text-sm">
              <span className="text-gray-700 dark:text-gray-400">Email</span>
              <input
                className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                name="email"
                value={input.email}
                onChange={(e) => onChangeHandler(e)}
                required
              />
            </label>
            {/* <label className="block mt-2 text-sm">
							<span className="text-gray-700 dark:text-gray-400">
								Tipe User
							</span>
							<input
								className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
								name="tipe_user"
								value={input.tipe_user}
								onChange={(e) => onChangeHandler(e)}
								required
							/>
						</label> */}
            <label className="block mt-2 text-sm">
              <span className="text-gray-700 dark:text-gray-400">CU ID</span>
              <select
                name="cu_id"
                value={input.cu_id}
                onChange={(e) => {
                  onChangeHandler(e);
                }}
                className="block w-full mt-1 text-sm dark:text-gray-300 dark:border-gray-600 dark:bg-gray-700 form-select focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
                list="list-koperasi"
                placeholder="Pilih Koperasi"
              >
                <option selected value={""}></option>
                {listKoperasi?.map((e, idx) => (
                  <option value={e.CU_id} key={idx}>{e.CU_id} - {e.Nama_CU}</option>
                ))}
              </select>
              {/* DATA LIST TEMPORARY NOT USED*/}
              {/* <datalist id="list-koperasi">
                <option value="" defaultValue />
                {listKoperasi.map((el) => {
                  return <option value={el.CU_id} />;
                })}
              </datalist> */}
            </label>
            <label className="block mt-2 text-sm">
              <span className="text-gray-700 dark:text-gray-400">Password</span>
              <input
                className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                name="password"
                value={input.password}
                onChange={(e) => onChangeHandler(e)}
                type="password"
                required
              />
            </label>

            {/* <!-- You should use a button here, as the anchor is only used for the example  --> */}
            <div className="mt-4">
              {/* {isLoading ? (
							<ButtonLoading />
						) : (
							<button
								type="submit"
								className="w-full flex justify-center py-2 my-1 rounded-lg text-white items-center text-sm bg-purple-600 focus:outline-none"
							>
								Add User
							</button>
						)} */}
              <button
                type="submit"
                className="w-full flex justify-center py-2 my-1 rounded-lg text-white items-center text-sm bg-purple-600 focus:outline-none"
              >
                Simpan
              </button>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
}
