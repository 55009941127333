import { Bar, Pie } from "react-chartjs-2";
import DatePicker from "react-datepicker";
import { useState, useRef, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchTRX, fetchUser, fetchTRXType, fetchTRXByStatus} from "../../store/actions/dashboardAction";
import { fetchCU} from "../../store/actions/ruangUsahaAction";

export default function Admin() {
  
  const dispatch = useDispatch();
  let { transactionData, trxSuccess, trxFailed, trxPending, trxLabel,
  totalUser, newUser, generation, generationTotal, trxType, isLoading } = useSelector((state) => state.dashboard);
  
  let { listCU } = useSelector((state) => state.ruangUsaha);

  const today = new Date();
  const prevMonth = today.getMonth() - 1;
  const thisYear = new Date(today.getFullYear(), 0, 1)

  const [startDate, setStartDate] = useState(thisYear);
  const [endDate, setEndDate] = useState(today);
  const [koperasi, setKoperasi] = useState('all');
  const [tipeTransaksi, setTipeTransaksi] = useState('all');

  useEffect(() => {
    const expired = +localStorage.getItem("expired")
    const now = new Date()

    if(localStorage.getItem("expired") && expired < now.getTime()){
        localStorage.clear();
        window.location.reload()
    }
    
    let payload = {}
    let start_date = new Date(startDate)
    start_date.setDate(start_date.getDate() + 1);

    if(localStorage.getItem('CU_Id') == 'null'){
      setKoperasi('all');
      payload = {
        CU_Id: 'all',
        start_date: start_date.toISOString().split("T")[0],
        end_date: endDate.toISOString().split("T")[0]
      }
    }else{
      setKoperasi(localStorage.getItem('CU_Id'));
      payload = {
        CU_Id: localStorage.getItem('CU_Id'),
        start_date: start_date.toISOString().split("T")[0],
        end_date: endDate.toISOString().split("T")[0]
      }
    }

    
    
      dispatch(fetchTRX(payload));
      dispatch(fetchUser(payload));
      dispatch(fetchCU())
      dispatch(fetchTRXType())
  }, [dispatch]);

  const startDateHandler = (newDate) => {
		setStartDate(newDate);
    
    let date = new Date(newDate);
    date.setDate(date.getDate() + 1);

		if (newDate <= endDate) {
      let payload = {
        CU_Id: koperasi,
        start_date: date.toISOString().split("T")[0],
        end_date: endDate.toISOString().split("T")[0]
      }
      if(tipeTransaksi != 'all'){
        payload.trx_type = tipeTransaksi
        dispatch(fetchTRXByStatus(payload))
      }else{
        dispatch(fetchTRX(payload));
      }
      dispatch(fetchUser(payload));
		}
	};

  const endDateHandler = (newDate) => {
		setEndDate(newDate);

    let date = new Date(newDate);
    date.setDate(date.getDate() + 1);

		if (startDate <= newDate) {
      let payload = {
        CU_Id: koperasi,
        start_date: date.toISOString().split("T")[0],
        end_date: newDate.toISOString().split("T")[0]
      }

      if(tipeTransaksi != 'all'){
        payload.trx_type = tipeTransaksi
        dispatch(fetchTRXByStatus(payload))
      }else{
        dispatch(fetchTRX(payload));
      }
      dispatch(fetchUser(payload));
		} 
	};

  function handleFilterKoperasi(e){
    setKoperasi(e.target.value);
    if(e.target.value == 'all'){
      let payload = {
        CU_Id: 'all',
        start_date: startDate.toISOString().split("T")[0],
        end_date: endDate.toISOString().split("T")[0]
      }
      if(tipeTransaksi != 'all'){
        payload.trx_type = tipeTransaksi
        dispatch(fetchTRXByStatus(payload))
      }else{
        dispatch(fetchTRX(payload));
      }
      dispatch(fetchUser(payload));
      
    }else{
      let payload = {
        CU_Id: e.target.value,
        start_date: startDate.toISOString().split("T")[0],
        end_date: endDate.toISOString().split("T")[0]
      }

      if(tipeTransaksi != 'all'){
        payload.trx_type = tipeTransaksi
        dispatch(fetchTRXByStatus(payload))
      }else{
        dispatch(fetchTRX(payload));
      }
      dispatch(fetchUser(payload));
    }
  }

  function handleTipeTransaksi(e){
    setTipeTransaksi(e.target.value);
    if(e.target.value == 'all'){
      let payload = {
        CU_Id: koperasi,
        start_date: startDate.toISOString().split("T")[0],
        end_date: endDate.toISOString().split("T")[0]
      }
      dispatch(fetchTRX(payload));
    }else{
      let payload = {
        CU_Id: koperasi,
        start_date: startDate.toISOString().split("T")[0],
        end_date: endDate.toISOString().split("T")[0],
        trx_type: e.target.value
      }
      dispatch(fetchTRXByStatus(payload));
    }
  }

  function quickDateFilter(e){
    if(e.target.value == 'Today'){
      let date = new Date();
      let start_date = date
      let end_date = date
      setStartDate(start_date);
      setEndDate(end_date);
      let payload = {
        CU_Id: koperasi,
        start_date: startDate.toISOString().split("T")[0],
        end_date: endDate.toISOString().split("T")[0]
      }
      if(tipeTransaksi != 'all'){
        payload.trx_type = tipeTransaksi
        dispatch(fetchTRXByStatus(payload))
      }else{
        dispatch(fetchTRX(payload));
      }
      dispatch(fetchUser(payload));
    }else if(e.target.value == 'Yesterday'){
      let date = new Date();
      let start_date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      let end_date = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
      setStartDate(start_date);
      setEndDate(end_date);
      let payload = {
        CU_Id: koperasi,
        start_date: startDate.toISOString().split("T")[0],
        end_date: endDate.toISOString().split("T")[0]
      }
      if(tipeTransaksi != 'all'){
        payload.trx_type = tipeTransaksi
        dispatch(fetchTRXByStatus(payload))
      }else{
        dispatch(fetchTRX(payload));
      }
      dispatch(fetchUser(payload));
    }else if(e.target.value == 'Last7Days'){
      let date = new Date();
      let start_date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      let end_date = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 6);
      setStartDate(start_date);
      setEndDate(end_date);
      let payload = {
        CU_Id: koperasi,
        start_date: startDate.toISOString().split("T")[0],
        end_date: endDate.toISOString().split("T")[0]
      }
      if(tipeTransaksi != 'all'){
        payload.trx_type = tipeTransaksi
        dispatch(fetchTRXByStatus(payload))
      }else{
        dispatch(fetchTRX(payload));
      }
      dispatch(fetchUser(payload));
    }else if(e.target.value == 'Last30Days'){
      let date = new Date();
      let start_date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      let end_date = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 30);
      setStartDate(start_date);
      setEndDate(end_date);
      let payload = {
        CU_Id: koperasi,
        start_date: startDate.toISOString().split("T")[0],
        end_date: endDate.toISOString().split("T")[0]
      }
      if(tipeTransaksi != 'all'){
        payload.trx_type = tipeTransaksi
        dispatch(fetchTRXByStatus(payload))
      }else{
        dispatch(fetchTRX(payload));
      }
      dispatch(fetchUser(payload));
    }else if(e.target.value == 'ThisMonth'){
      let date = new Date();
      let start_date = new Date(date.getFullYear(), date.getMonth(), 1);
      let end_date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      setStartDate(start_date);
      setEndDate(end_date);
      let payload = {
        CU_Id: koperasi,
        start_date: startDate.toISOString().split("T")[0],
        end_date: endDate.toISOString().split("T")[0]
      }
      if(tipeTransaksi != 'all'){
        payload.trx_type = tipeTransaksi
        dispatch(fetchTRXByStatus(payload))
      }else{
        dispatch(fetchTRX(payload));
      }
      dispatch(fetchUser(payload));
    }else if(e.target.value == 'ThisYear'){
      let date = new Date();
      let start_date = new Date(date.getFullYear(), 0, 1);
      let end_date = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      setStartDate(start_date);
      setEndDate(end_date);
      let payload = {
        CU_Id: koperasi,
        start_date: startDate.toISOString().split("T")[0],
        end_date: endDate.toISOString().split("T")[0]
      }
      if(tipeTransaksi != 'all'){
        payload.trx_type = tipeTransaksi
        dispatch(fetchTRXByStatus(payload))
      }else{
        dispatch(fetchTRX(payload));
      }
      dispatch(fetchUser(payload));
    }
  }

  const trxBarOptions = {
      scales: {
           xAxes: [{
               stacked: true
           }],
           yAxes: [{
               stacked: true
           }]
       },
      cornerRadius: 20,
      aspectRatio: 4
   }

  let trxBarData ={ 
      datasets:[{
        label: 'Berhasil',
          data :trxSuccess,
          backgroundColor: "rgba(37, 159, 81, 1)"
        },
        {
          label: 'Gagal',
          data:  trxFailed,
          backgroundColor: "rgba(214, 34, 34, 1)"
        },
        {
          label: 'Pending',
          data:  trxPending,
          backgroundColor: 
          "rgba(242, 183, 113, 1)"
        }],
      labels:trxLabel
      // labels: Object.keys(transactionData)
  }

  const userPieOptions = {
           responsive: true,
          /**
           * Default legends are ugly and impossible to style.
           * See examples in charts.html to add your own legends
           *  */
          legend: {
            display: false,
          },
          plugins: {
            datalabels: {
              display: true,
              color: "white",
              formatter: (value, ctx) => {
                let sum = 0;
                let dataArr = ctx.chart.data.datasets[0].data;
                dataArr.map((data) => {
                  return (sum += data);
                });
                let percentage = ((value * 100) / sum).toFixed(2) + "%";
                return percentage;
              },
            },
          },
  }

  let userPieData = {
    datasets: [
      {
        label: 'My First Dataset',
        data: generationTotal,
        backgroundColor: ["#0694a2", "#1c64f2", "#7e3af2", "#f2b51c", "#f2a11c"],
      },
    ],
    labels: generation,
  }

  return (
    
    
    <div className="container grid flex flex-col flex-1">
        {/** Navbar */}
        {
          localStorage.getItem('CU_Id') == 'null' ?
          <header className="relative z-5 py-4 bg-white flex justify-between shadow-md dark:bg-gray-800">
            <div className="container flex items-center md:justify-end justify-between h-full px-6 mx-auto text-black-600 dark:text-purple-300">
                <div className="flex w-1/3 items-left text-xl font-bold font-['Open_Sans']">
                    <h5>Dashboard Cuso Mobile Admin</h5>
                </div>

                <div className="flex w-1/6 items-center">
                </div>

                {/** Filter */}
                <div className="flex w-3/5 items-center">
                  <ul className="flex items-center just flex-shrink-0 space-x-6">
                    <div class="flex items-center">
                      {/** Start Date */}
                      <div className="relative w-1/6">
                          <DatePicker
                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-5 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            selected={startDate}
                            placeholderText="start date"
                            dateFormat="dd/MM/yyyy"
                            // isClearable
                            onChange={(date) => startDateHandler(date)}
                          />
                      </div>
                      
                      {/** Date Divider */}
                      <span class="mx-2 text-gray-500">to</span>
                    
                      {/** End Date */}
                      <div class="relative w-1/6">
                        <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                            <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd"></path></svg>
                        </div>
                            <DatePicker
                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-5 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            selected={endDate}
                            placeholderText="end date"
                            dateFormat="dd/MM/yyyy"
                            // isClearable
                            onChange={(date) => endDateHandler(date)}
                          />
                      </div>

                      {/** Koperasi */}
                      <div class="relative ml-3 w-1/6">
                        <select onChange={handleFilterKoperasi} className="block appearance-none text-sm w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                          <option value="all">Semua Koperasi</option>
                            {listCU.map((data, idx) => {
                            return <option value={data.CU_Id} key={idx}>{data.CU_Id} - {data.Nama_CU}</option>;
                            })}                    
                        </select>
                      </div>                      

                      {/** Date Quick Filter */}
                      <div class="mx-3 w-1/12">
                        <select onChange={quickDateFilter} className="block appearance-none text-sm w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                          <option selected disabled>
                            Filter
                          </option>
                          <option value="Today">Today</option>
                          <option value="Yesterday">Yesterday</option>
                          <option value="Last7Days">Last 7 Days</option>
                          <option value="Last30Days">Last 30 Days</option>
                          <option value="ThisMonth">This Month</option>
                          <option value="ThisYear">This Year</option>            
                        </select>
                      </div>

                    </div>
                  </ul>
                </div>
            </div>
          </header>
          :
          <header className="relative z-5 py-4 bg-white flex justify-between shadow-md dark:bg-gray-800">
            <div className="container flex items-center md:justify-end justify-between h-full px-6 mx-auto text-black-600 dark:text-purple-300">
                <div className="flex w-1/3 items-left text-xl font-bold font-['Open_Sans']">
                    <h5>Dashboard Cuso Mobile</h5>
                </div>

                <div className="flex w-1/2 items-center text-xl font-bold font-['Open_Sans']">
                </div>

                {/** Filter */}
                <div className="flex w-3/5 items-center">
                  <ul className="flex items-center just flex-shrink-0 space-x-6">
                    <div class="flex items-center justify-center">
                      {/** Start Date */}
                      <div className="relative w-1/5">
                          <DatePicker
                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-5 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            selected={startDate}
                            placeholderText="start date"
                            dateFormat="dd/MM/yyyy"
                            // isClearable
                            onChange={(date) => startDateHandler(date)}
                          />
                      </div>
                      
                      {/** Date Divider */}
                      <span class="mx-2 text-gray-500">to</span>
                    
                      {/** End Date */}
                      <div class="relative w-1/5">
                        <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                            <svg class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z" clip-rule="evenodd"></path></svg>
                        </div>
                            <DatePicker
                            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-5 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                            selected={endDate}
                            placeholderText="end date"
                            dateFormat="dd/MM/yyyy"
                            onChange={(date) => endDateHandler(date)}
                          />
                      </div>
                      
                      <div class="mx-3 w-1/6">
                        <select onChange={quickDateFilter} className="block appearance-none text-sm w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                          <option selected disabled>
                            Filter
                          </option>
                          <option value="Today">Today</option>
                          <option value="Yesterday">Yesterday</option>
                          <option value="Last7Days">Last 7 Days</option>
                          <option value="Last30Days">Last 30 Days</option>
                          <option value="ThisMonth">This Month</option>
                          <option value="ThisYear">This Year</option>            
                        </select>
                      </div>

                    </div>
                  </ul>
                </div>
            </div>
          </header>
        }
        

        {/* <!-- Main content --> */}
      <div className="h-full bg-slate-100 flex justify-start ml-0 flex-wrap overflow-y-auto">

		    {/* <!-- Bars chart --> */}
        <div className="bg-white shadow-lg md:w-full h-50 mt-10 mx-5 rounded-lg flex flex-col content-center overflow-hidden relative">
          <div className="flex flex-row">
            {
              isLoading ? <h4 className="mb-4 w-1/2 font-semibold text-gray-800 mt-5 dark:text-gray-300 ml-10">Data is Loading, Please Kindly Wait ....</h4>
              : <h4 className="mb-4 w-1/2 font-semibold text-xl text-gray-800 mt-5 dark:text-gray-300 ml-10">Total Transaksi</h4>
            }

            <div class="relative mr-3 w-1/4">
            </div>

            {/** Tipe Transaksi */}
            <div class="relative mr-3 mt-5 w-1/4">
              <select onChange={handleTipeTransaksi} className="block appearance-none text-sm w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                  <option value="all">Semua Transaksi</option>
                  {trxType.map((data, idx) => {
                    if(tipeTransaksi == data.Jenis_Transaksi_Detail){
                      return <option value={data.Jenis_Transaksi_Detail} key={idx} selected>{data.Nama_Jenis_Transaksi_Detail}</option>;
                    }else{
                      return <option value={data.Jenis_Transaksi_Detail} key={idx}>{data.Nama_Jenis_Transaksi_Detail}</option>;
                    }
                  })}                    
              </select>
            </div>

          </div>
          <div className="flex flex-col w-full">
                <Bar  data={trxBarData} options={trxBarOptions}/>
          </div>
        </div>

        {/* <!-- Total User --> */}
        <div className="bg-white shadow-lg md:w-1/4 h-50 mb-2 mt-10 mx-5 rounded-lg flex flex-col content-left overflow-hidden relative">
          <div className="bg-slate-300 flex align-center justify-left ml-10">
              <img
                  className="h-24 w-24 object-cover"
                  src="https://i.imgur.com/HGULiq6.png"
                  alt=""
              />
          </div>
          <div className="h-25 ml-10 mt-1 w-40 text-xl font-['Open_Sans']">
              Total Pengguna Mobile Apps
          </div>
          {
            totalUser ? 
            <div className="m-3 ml-10 h-10 font-semibold text-2xl font-['Open_Sans']">{totalUser}</div>
            :
            <div className="m-3 ml-10 h-10 font-semibold text-2xl font-['Open_Sans']">-</div>
          }
        </div>

        {/* <!-- New User --> */}
        <div className="bg-white shadow-lg md:w-1/4 h-50 mb-2 mt-10 mx-5 rounded-lg flex flex-col content-center overflow-hidden relative">
          <div className="bg-white flex align-center justify-left ml-10">
              <img
                  className="h-24 w-24 object-cover"
                  src="https://i.imgur.com/wRj79Ho.png"
                  alt=""
              />
          </div>
          <div className="h-25 ml-10 mt-1 w-40 text-xl font-['Open_Sans']">
              Pengguna Baru Mobile Apps
          </div>
          {
            newUser ? 
            <div className="m-3 mb-0 ml-10 h-10 font-semibold text-2xl font-['Open_Sans']">{newUser}</div>
            :
            <div className="m-3 ml-10 h-10 font-semibold text-2xl font-['Open_Sans']">-</div>
          }
          {
            newUser && startDate && endDate ? 
            <div className="m-3 mb-0 ml-10 h-10 font-semibold text-sm font-['Open_Sans'] text-green-600">+{Math.floor(newUser/((endDate - startDate) / (1000 * 60 * 60* 24)))}/day</div>
            :
            <div className="m-3 ml-10 h-10 font-semibold text-2xl font-['Open_Sans']">-</div>
          }
        </div>

        {/* <!-- Pie Chart --> */}
        <div className="bg-white shadow-lg md:w-2/5 h-50 mt-10 ml-5 rounded-lg flex flex-col content-start justify-start overflow-hidden relative">
          <div className="bg-white flex align-center justify-center font-bold font-['Open_Sans']">
              Usia Pengguna Mobile Apps
          </div>
          <div className="bg-white  md:w-full h-50 rounded-lg mt-5 mb-5 flex flex-col content-center justify-center overflow-hidden relative">
              <div className="flex flex-col mx-auto items-left align-center justify-center w-3/4">
                  <Pie
                      data={userPieData}
                      options={userPieOptions}
                  />
              </div>
        
              {/* <!-- Chart legend --> */}
              <div className="flex flex-wrap mx-auto items-left align-center ml-5 mr-5 justify-start w-full"> 
                  <div class="flex md:w-1/3 items-center">
                  <span class="inline-block w-3 h-3 mr-1 rounded-full" style={{backgroundColor:"#0694a2"}}></span>
                  <span>Post War</span>
                  </div>
                  <div class="flex md:w-1/3 items-center">
                  <span class="inline-block w-3 h-3 mr-1 rounded-full" style={{backgroundColor:"#1c64f2"}}></span>
                  <span>Boomers</span>
                  </div>
                  <div class="flex md:w-1/3 items-center">
                  <span class="inline-block w-3 h-3 mr-1 rounded-full" style={{backgroundColor:"#7e3af2"}}></span>
                  <span>Gen X</span>
                  </div>
                  <div class="flex md:w-1/3 items-center">
                  <span class="inline-block w-3 h-3 mr-1 rounded-full" style={{backgroundColor:"#f2b51c"}}></span>
                  <span>Millenials</span>
                  </div>
                  <div class="flex md:w-1/3 items-center">
                  <span class="inline-block w-3 h-3 mr-1 rounded-full" style={{backgroundColor:"#f2a11c"}}></span>
                  <span>Gen Z</span>
                  </div>
              </div>
          </div>
        </div>
        
      </div>
    </div>
  );
}
