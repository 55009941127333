import { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import axios from "../../config/axiosinstance";

export default function ResetPassword() {
  const query = new URLSearchParams(useLocation().search);
  const [error, setError] = useState("");
  const [checkToken, setCheckToken] = useState(false);
  const [input, setInput] = useState({
    password: "",
    konfirmasiPassword: "",
    token: query.get("token"),
  });
  const history = useHistory();

  useEffect(() => {
    const expired = +localStorage.getItem("expired")
    const now = new Date()

    if(localStorage.getItem("expired") && expired < now.getTime()){
        localStorage.clear();
        window.location.reload()
    }
    
    axios({
      url: "check",
      method: "GET",
      headers: {
        token: input.token,
      },
    })
      .then(({ data }) => {
        setCheckToken(true);
      })
      .catch(({ response }) => {
        history.push("/login");
      });
  }, [history, input.token]);

  const onChangeHandler = (e) => {
    setError("");
    const { name, value } = e.target;
    setInput({
      ...input,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (input.password !== input.konfirmasiPassword) {
      setError("Konfirmasi password anda tidak sesuai");
    } else {
      axios({
        url: "confirm",
        method: "POST",
        headers: {
          token: input.token,
        },
        data: {
          password: input.password,
        },
      })
        .then(({ data }) => {
          history.push("/login");
        })
        .catch(({ response }) => {
          // setNotif(response.data.message);
          console.log(response);
        });
    }
  };
  if (!checkToken) {
    return <p>Loading ...</p>;
  }
  return (
    <main className="bg-gray-50">
      <div className="container p-4 mx-auto flex justify-center items-center min-h-screen">
        <div className="w-full md:w-1/2 bg-white dark:bg-gray-700 p-6 rounded-lg shadow-lg">
          <h1 className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">
            Buat password baru
          </h1>
          <form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <label className="block mt-2 text-sm">
              <span className="text-gray-700 dark:text-gray-400">
                Password baru
              </span>
              <input
                className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                name="password"
                placeholder="Ketik password baru anda"
                type="password"
                value={input.password}
                onChange={(e) => onChangeHandler(e)}
                required
              />
            </label>
            <label className="block mt-2 text-sm">
              <span className="text-gray-700 dark:text-gray-400">
                Konfirmasi password
              </span>
              <input
                className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                name="konfirmasiPassword"
                placeholder="Ketik ulang password baru anda"
                value={input.konfirmasiPassword}
                onChange={(e) => onChangeHandler(e)}
                type="password"
                required
              />
            </label>
            {error && (
              <span className="text-xs text-red-600 dark:text-red-400">
                {error}
              </span>
            )}
            <div className="mt-4">
              <button
                type="submit"
                className="w-full flex justify-center py-2 my-1 rounded-lg text-white items-center text-sm bg-purple-600 focus:outline-none"
              >
                Simpan
              </button>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
}
