import { Link, useRouteMatch } from "react-router-dom";
import formatRupiah from "../../utils/formatRupiah";

export default function Row({ data, idx }) {
  const { url } = useRouteMatch();
  return (
    <tr className="text-gray-700 dark:text-gray-400">
      <td className="px-4 py-3 text-sm">{idx + 1}</td>
      <td className="px-4 py-3 text-sm">{data.CU_Id}</td>
      <td className="px-4 py-3 text-sm">{data.Area}</td>
      <td className="px-4 py-3 text-sm">{data.Nama_CU}</td>
      <td className="px-4 py-3 text-sm truncate">
        {formatRupiah(data.Deposit_Setoran)}
      </td>
      <td className="px-4 py-3">
        <Link to={`${url}/mutasi-setoran/${data.CU_Id}`}>
          <button
            className="flex items-center justify-between px-2 py-1 text-sm font-medium leading-5 text-purple-600 rounded-xl dark:text-purple-600 focus:outline-none focus:shadow-outline-gray bg-gray-200"
            aria-label="Edit"
          >
            Detail
          </button>
        </Link>
      </td>
    </tr>
  );
}
