import axios from "../../config/axiosinstance";
import Swal from "sweetalert2";

//Fetch TRX Data
export const fetchTRX = (payload) => {
	return (dispatch) => {
		dispatch({
			type: "SET_DASHBOARD_LOADING",
		});
		axios({
			method: "GET",
			url: "mainpage/trx/date",
			headers: {
				token: localStorage.getItem("token"),
			},
            params :{
                CU_Id : payload.CU_Id,
                start_date: payload.start_date,
                end_date: payload.end_date
            }
		})
			.then(({ data }) => {
				const allData = data.data
				let payload = {}
				payload.allData = allData
				payload.success = []
				payload.pending = []
				payload.failed = []
				payload.label = Object.keys(allData).filter(item => item != 'totalTrx')
				payload.label.forEach(item => {
					payload.success.push(allData[item].success)
					payload.pending.push(allData[item].pending)
					payload.failed.push(allData[item].failed)
				})
				dispatch({
					type: "SET_TRX_DATA",
					payload: payload,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
};

//Fetch TRX Data By Status
export const fetchTRXByStatus = (payload) => {
	return (dispatch) => {
		dispatch({
			type: "SET_DASHBOARD_LOADING",
		});
		axios({
			method: "GET",
			url: "mainpage/trx/date",
			headers: {
				token: localStorage.getItem("token"),
			},
            params :{
                CU_Id : payload.CU_Id,
                start_date: payload.start_date,
                end_date: payload.end_date,
				trx_type: payload.trx_type
            }
		})
			.then(({ data }) => {
				const allData = data.data
				let payload = {}
				payload.allData = allData
				payload.success = []
				payload.pending = []
				payload.failed = []
				payload.label = Object.keys(allData).filter(item => item != 'totalTrx')
				payload.label.forEach(item => {
					payload.success.push(allData[item].success)
					payload.pending.push(allData[item].pending)
					payload.failed.push(allData[item].failed)
				})
				dispatch({
					type: "SET_TRX_DATA",
					payload: payload,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
};

//Fetch User Data
export const fetchUser = (payload) => {
	return (dispatch) => {
		dispatch({
			type: "SET_DASHBOARD_LOADING",
		});
		axios({
			method: "GET",
			url: "mainpage/user",
			headers: {
				token: localStorage.getItem("token"),
			},
            params :{
                CU_Id : payload.CU_Id,
                start_date: payload.start_date,
                end_date: payload.end_date
            }
		})
			.then(({ data }) => {
				const allData = data.data
				let payload = {}
				payload.allData = allData
				payload.totalUser = allData.totalUser
				payload.newUser = allData.newUser
				payload.generationTotal = []
				payload.generation = Object.keys(allData.generation)
				payload.generation.forEach(item => {
					payload.generationTotal.push(allData.generation[item])
				})
				dispatch({
					type: "SET_USER_STATS",
					payload: payload,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
};

//Fetch TRX Type
export const fetchTRXType = (payload) => {
	return (dispatch) => {
		dispatch({
			type: "SET_DASHBOARD_LOADING",
		});
		axios({
			method: "GET",
			url: "mainpage/trxtype",
			headers: {
				token: localStorage.getItem("token"),
			}
		})
			.then(({ data }) => {
				dispatch({
					type: "SET_TRX_TYPE",
					payload: data.data,
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};
};
