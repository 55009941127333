// import { Table, Card, Form } from "../components";
// import AllChart from "../components/Chart/AllChart";

export default function ComponentPage() {
	return (
		<div>
			All Component
			{/* <Table />
      <hr />
      <Card />
      <hr />
			<Form />
      <hr />
      <AllChart /> */}
		</div>
	);
}
