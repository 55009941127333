import { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Editor } from "@tinymce/tinymce-react";
import Swal from "sweetalert2"
import { fetchCU, fetchKategori, postBerita } from "../../store/actions/beritaAction";

export default function UploadBerita() {
  const history = useHistory();
  const dispatch = useDispatch();
  let { listCU } = useSelector((state) => state.ruangUsaha);
  let { listKategori } = useSelector((state) => state.berita);

  useEffect(() => {
    const expired = +localStorage.getItem("expired")
    const now = new Date()

    if(localStorage.getItem("expired") && expired < now.getTime()){
        localStorage.clear();
        window.location.reload()
    }
    
    dispatch(fetchCU());
    dispatch(fetchKategori());
  }, [dispatch]);

  const [image, setImage] = useState('')
  const [imagePreview, setImagePreview] = useState('')
  const [imageUploaded, setImageUploaded] = useState(false)
  const [judul, setJudul] = useState('')
  const [informasiDetail, setInformasiDetail] = useState('')
  const [koperasi, setKoperasi] = useState(null)
  const [kategori, setKategori] = useState(null)
  const [tipeNews, setTipeNews] = useState(null)

  //alert
  function alert(text){
    Swal.fire({
      title: "Oops!",
      text: text,
      icon: "error",
      showConfirmButton: true,
      position: "center",
      allowOutsideClick: false,
    });
  }

  //Foto Produk
  function handleImageChange(e){
    if(e.target.files && e.target.files[0] && e.target.files[0].size < 300000){
      setImage(e.target.files[0])
      let reader = new FileReader()

      reader.onload = function(e){
        setImagePreview(e.target.result)
        setImageUploaded(true)
      }

      reader.readAsDataURL(e.target.files[0])
    }else if(e.target.files && e.target.files[0] && e.target.files[0].size >= 300000){
      Swal.fire({
        title: "Oops!",
        text: "Ukuran gambar terlalu besar, silahkan pilih gambar lain",
        icon: "error",
        showConfirmButton: true,
        position: "center",
        allowOutsideClick: false,
      });
    }
  }

  //judul
  function handleJudulChange(e){
    if(e.target.value.length){
      setJudul(e.target.value)
    }else{
      setJudul("")
    }
  }

  //Informasi Detail
  function handleInformasiDetailChange(e){
    setInformasiDetail(e)
  }

  //CU_Id
  function handleKoperasiChange(e){
    if(e.target.value){
      setKoperasi(e.target.value)
    }else{
      setKoperasi(null)
    }
  }
  
  //Kode Kategori
  function handleKategoriChange(e){
    if(e.target.value){
        setKategori(e.target.value)
    }else{
        setKategori(null)
    }
  }
  
  //Tipe News
  function handleTipeNewsChange(e){
    if(e.target.value){
        setTipeNews(+e.target.value)
    }else{
        setTipeNews(null)
    }
  }

  //submit
  function handleSubmit(e){
    e.preventDefault()

    let html = ''
    
    if(!image){
      alert("Silahkan pilih gambar produk terlebih dahulu")
    }else if(!judul){
      alert("Silahkan isi judul berita terlebih dahulu")
    }else if(!informasiDetail){
      alert("Silahkan isi deskripsi berita terlebih dahulu")
    }else if(localStorage.getItem("CU_Id") == "null" && !koperasi){
      alert("Silahkan Pilih Koperasi terlebih dahulu")
    }else{
      html += `<div style="text-align:left;font-family:sans">`

      if(localStorage.getItem('CU_Id') === 'null' && koperasi){
        html += `<p>Koperasi : ${listCU.find(item => item.CU_Id == koperasi).Nama_CU}</p>`
      }else{
        html += `<p>Koperasi : ${listCU.find(item => item.CU_Id == localStorage.getItem('CU_Id')).Nama_CU}</p>`
      }

      html += `<p>Judul : ${judul}</p>`
      html += `<p>Deskripsi Berita : ${informasiDetail}</p>`
      html += `<p>Kategori : ${listKategori.find(item => item.Kode_Kategori == kategori).Nama_Kategori}</p>`
      tipeNews === 1 ? html += `<p>Tipe Berita : Lokal</p>` : html += `<p>Tipe Berita : Global</p>`
      html += `</div>`

      Swal.fire({
        title: 'Apakah Anda Sudah Yakin?',
        html: html,
        imageUrl: imagePreview,
        imageWidth: 400,
        imageHeight: 200,
        showCancelButton: true,
        confirmButtonText: 'Simpan',
        denyButtonText: `Batal`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          let newDate = new Date()
          let date = newDate.getDate();
          let month = newDate.getMonth() + 1;
          let year = newDate.getFullYear();
          const separator = '-'
          const tanggal = `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date<10?`0${date}`:`${date}`}`

          //jika admin maka CU_Id dipilih, jika bukan admin maka cu id tergantung akun
          let CU_Id = 0
          if(localStorage.getItem('CU_Id') === 'null'){
            CU_Id = koperasi
          }else{
            CU_Id = localStorage.getItem('CU_Id')
          }
          
          const fd = new FormData()
          fd.append('Foto', image)
          fd.append('Judul', judul)
          fd.append('CU_Id', CU_Id)
          fd.append('Tanggal', tanggal)
          fd.append('Informasi_Detail', informasiDetail)
          fd.append('Kode_Kategori', kategori)
          fd.append('Tipe_News', +tipeNews)
          dispatch(postBerita(fd));
        } else if (result.isDenied) {
          Swal.fire('Changes are not saved', '', 'info')
        }
      })
    }    
  }

  return (
    <div>
      <main className="h-full pb-16 overflow-y-auto">
			<div className="container px-6 mx-auto grid">
				<div className="px-4 py-3 mt-8 mb-8 bg-white rounded-lg shadow-md dark:bg-gray-800">

          {/** Upload Form*/}
          <form className="w-full" onSubmit={handleSubmit}>
            {/** Judul Form */}
            <div className="flex flex-wrap w-full mb-10">
              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <h1 className="font-sans font-bold text-2xl">Upload Berita</h1>
              </div>
            </div>

            {/** Image */}
            <div className="flex flex-wrap w-full mb-10">
              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <h1 className="font-['Open_Sans'] font-bold text-xl">Foto Berita</h1>
                <p className="text-sm font-['Open_Sans']">
                    Pilih foto berita atau tarik dan letakkan
                    foto di sini.
                </p>
                <p className="text-sm">
                    Format gambar .jpg .jpeg .png dan
                    ukuran maximum 300kb.
                </p>
              </div>

              {
                imageUploaded ? 
                <div className="w-full md:w-2/3 px-3">
                  <img className="w-full px-3 max-h-100" src={imagePreview}/>
                </div>
                 : 
                <div className="w-full md:w-2/3 px-3">
                    <div className="flex items-center justify-center w-full">
                      <label
                          className="flex flex-col w-full h-100 border-4 border-blue-200 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          <div className="flex flex-col items-center justify-center pt-7">
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-gray-400 group-hover:text-gray-600"
                                  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                  Attach a file</p>
                          </div>
                          <input id="upload-image-produk" type="file" accept=".jpg,.png,.png,.jpeg" className="opacity-0" onChange={handleImageChange}/>
                      </label>
                  </div>
                </div>
              }  
            </div>

            {/** Divider 1*/}
            <div className="flex flex-wrap w-full mb-6 border-dashed border-t-2 border-gray-200">
              <div className="w-full md:w-1/3 px-3 mt-10 mb-6 md:mb-0">
                <h1 className="font-sans font-bold text-2xl">Informasi Berita</h1>
              </div>
            </div>

            {/** Judul */}
            <div className="flex flex-wrap w-full mb-10">
              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <h1 className="font-['Open_Sans'] font-bold text-xl mb-2">Judul</h1>
                <p className="text-sm font-['Open_Sans']">
                Judul dari Berita.
                </p>
              </div>
              <div className="w-full md:w-2/3 px-3">
                <label className="block text-sm mt-10">
                  <input
                    className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                    placeholder="Judul"
                    onChange={handleJudulChange}
                  />
                </label>
              </div>  
            </div>
            
            {/** Deskripsi Berita */}
            <div className="flex flex-wrap w-full mb-10">
              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <h1 className="font-['Open_Sans'] font-bold text-xl mb-2">Deskripsi Berita</h1>
                <p className="text-sm font-['Open_Sans']">
                Deskripsi dari Berita.
                </p>
              </div>
              <div className="w-full md:w-2/3 px-3 mt-6">
                <label className="block mt-4 text-sm">
                  <Editor
                    apiKey={window.env.REACT_APP_TINY_MCE_API_KEY}
                    initialValue="<p>This is the initial content of the editor</p>"
                    init={{
                      height: 600,
                      menubar: false,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent link | removeformat | help",
                    }}
                    onEditorChange={handleInformasiDetailChange}
                  />
                </label>
              </div>  
            </div>

            {/** Nama Koperasi (untuk admin) */}
            {
              localStorage.getItem('CU_Id') === 'null' ? <div>
                {/** CU_Id */}
                <div className="flex flex-wrap w-full mb-10">
                  <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                    <h1 className="font-['Open_Sans'] font-bold text-xl mb-2">Koperasi</h1>
                    <p className="text-sm font-['Open_Sans']">
                    Silahkan Pilih Koperasi
                    </p>
                  </div>
                  <div className="w-full md:w-2/3 px-3 mt-5">
                      <select onChange={handleKoperasiChange} className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                        <option selected disabled >Pilih Koperasi</option>
                        {listCU.map((data, idx) => {
                          return <option value={data.CU_Id} key={idx}>{data.Nama_CU}</option>;
                        })}                    
                      </select>
                      
                  </div>  
                </div>
              </div>:<div>
              </div>
            }

            {/** Kode Kategori */}
            <div className="flex flex-wrap w-full mb-10">
              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <h1 className="font-['Open_Sans'] font-bold text-xl mb-2">Kategori Berita</h1>
                <p className="text-sm font-['Open_Sans']">
                Pilih 1 Kategori Berita.
                </p>
              </div>
              <div className="w-full md:w-2/3 px-3 mt-10">
                  <select onChange={handleKategoriChange} className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                    <option selected disabled>Pilih Kategori</option>
                    {listKategori.map((data, idx) => {
                      return <option value={data.Kode_Kategori} key={idx}>{data.Nama_Kategori}</option>;
                    })}                    
                  </select>
              </div>  
            </div>
            
            {/** Tipe News */}
            <div className="flex flex-wrap w-full mb-10">
              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <h1 className="font-['Open_Sans'] font-bold text-xl mb-2">Tipe Berita</h1>
                <p className="text-sm font-['Open_Sans']">
                Pilih Tipe Berita
                </p>
              </div>
              <div className="w-full md:w-2/3 px-3">
              <label className="inline-flex items-center text-gray-600 dark:text-gray-400">
                  <input
                    type="radio"
                    className="text-purple-600 form-radio focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
                    name="tipeNews"
                    value="0"
                    onChange={handleTipeNewsChange}
                  />
                  <span className="ml-2">Global</span>
                </label>
                <label className="inline-flex items-center ml-6 text-gray-600 dark:text-gray-400">
                  <input
                    type="radio"
                    className="text-purple-600 form-radio focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:focus:shadow-outline-gray"
                    name="tipeNews"
                    value="1"
                    onChange={handleTipeNewsChange}
                  />
                  <span className="ml-2">Local</span>
                </label>
              </div>  
            </div>

            {/** Submit */}
            <div className="flex flex-wrap justify-center w-full mb-10">
              <button
                type="submit"
                className="w-full md:w-1/3 flex justify-center py-2 my-1 rounded-lg text-white items-center text-sm bg-green-700 focus:outline-none"
              >
                Simpan
              </button>
            </div>
          </form>
				</div>
			</div>
		</main>
    </div>
  );
}
