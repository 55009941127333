import { useState, useRef, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactCrop from 'react-image-crop'
import { Editor } from "@tinymce/tinymce-react";
import Swal from "sweetalert2"
import { fetchCurrentProduk, updateInfoProduk } from "../../store/actions/infoProdukAction";

export default function UpdateInfoProduk(props) {
  let { koperasi, produk } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  let { currentProduk, listCU } = useSelector((state) => state.infoProduk);

  useEffect(() => {
    const expired = +localStorage.getItem("expired")
    const now = new Date()

    if(localStorage.getItem("expired") && expired < now.getTime()){
        localStorage.clear();
        window.location.reload()
    }
    
    if (localStorage.getItem("CU_Id") != "null") {
        dispatch(fetchCurrentProduk(localStorage.getItem("CU_Id"), produk));
    }else{
        dispatch(fetchCurrentProduk(koperasi, produk));
    }
    
  }, [dispatch]);

  const [image, setImage] = useState('')
  const [imagePreview, setImagePreview] = useState('')
  const [imageUploaded, setImageUploaded] = useState(false)
  const [judul, setJudul] = useState('')
  const [infoDetail, setInfoDetail] = useState("")

  //alert
  function alert(text){
    Swal.fire({
      title: "Oops!",
      text: text,
      icon: "error",
      showConfirmButton: true,
      position: "center",
      allowOutsideClick: false,
    });
  }

  //Foto Produk
  function handleImageChange(e){
    if(e.target.files && e.target.files[0] && e.target.files[0].size < 300000){
      setImage(e.target.files[0])
      let reader = new FileReader()

      reader.onload = function(e){
        setImagePreview(e.target.result)
        setImageUploaded(true)
      }

      reader.readAsDataURL(e.target.files[0])
    }else if(e.target.files && e.target.files[0] && e.target.files[0].size >= 300000){
      Swal.fire({
        title: "Oops!",
        text: "Ukuran gambar terlalu besar, silahkan pilih gambar lain",
        icon: "error",
        showConfirmButton: true,
        position: "center",
        allowOutsideClick: false,
      });
    }
  }

  //Judul Produk
  function handleJudulChange(e){
    if(e.target.value.length){
      setJudul(e.target.value)
    }else{
      setJudul("")
    }
  }

  //Deskripsi Produk
  const handleEditorChange = (content) => {
    setInfoDetail(content)
  };
  
  //submit
  function handleSubmit(e){
    e.preventDefault()

    let html = `<div style="text-align:left;font-family:sans">`
    html += `<p>Koperasi : ${listCU.find(item => item.CU_Id == koperasi).Nama_CU}</p>`

    const fd = new FormData()
    fd.append('Kode_Produk', produk)
        
    if(image){
      fd.append('Foto', image)
    }else{
      fd.append('Foto_URL', currentProduk.Foto_URL)
    }
        
    if(judul){
      fd.append('Judul', judul)
      html += `<p>Judul : ${judul}</p>`
    }else{
      fd.append('Judul', currentProduk.Judul)
      html += `<p>Nama Produk : ${currentProduk.Judul}</p>`
    }
        
    if(infoDetail){
      fd.append('Informasi_Detail', infoDetail)
      html += `<p>Informasi Detail : ${infoDetail}</p>`
    }else{
      fd.append('Informasi_Detail', currentProduk.Informasi_Detail)
      html += `<p>Informasi Detail : ${currentProduk.Informasi_Detail}</p>`
    }

    Swal.fire({
          title: 'Apakah Anda Sudah Yakin?',
          html: html,
          imageUrl: imagePreview || currentProduk.Foto_URL,
          imageWidth: 400,
          imageHeight: 200,
          showCancelButton: true,
          confirmButtonText: 'Simpan',
          denyButtonText: `Batal`,
        }).then((result) => {
          if (result.isConfirmed){
            dispatch(updateInfoProduk(fd, koperasi))
            history.push('/info-produk')
          }else if (result.isDenied) {
            Swal.fire('Changes are not saved', '', 'info')
          }
        })

    
  }

  return (
    <div>
      <main className="h-full pb-16 overflow-y-auto">
			<div className="container px-6 mx-auto grid">
				<div className="px-4 py-3 mt-8 mb-8 bg-white rounded-lg shadow-md dark:bg-gray-800">

          {/** Update Form*/}
          <form 
          className="w-full" onSubmit={handleSubmit}
          >
            {/** Judul Form */}
            <div className="flex flex-wrap w-full mb-10">
              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <h1 className="font-sans font-bold text-2xl">{currentProduk.Judul}</h1>
              </div>
            </div>

            {/** Image */}
            <div className="flex flex-wrap w-full mb-10">
              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <h1 className="font-['Open_Sans'] font-bold text-xl">Foto</h1>
                <p className="text-sm font-['Open_Sans']">
                    Format gambar .jpg .jpeg .png dan
                    ukuran maximum 300kb.
                </p>
              </div>

              {
                imageUploaded ? 
                <div className="w-full md:w-2/3 px-3">
                    <img className="w-60 px-3 h-96" src={imagePreview}/>
                  <div className="flex items-center mt-5 justify-center w-full">
                      <label
                          className="flex flex-col w-full h-100 border-4 border-blue-200 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          <div className="flex flex-col items-center justify-center pt-7">
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-gray-400 group-hover:text-gray-600"
                                  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                  Update Photo</p>
                          </div>
                          <input id="upload-image-produk" type="file" accept=".jpg,.png,.png,.jpeg" className="opacity-0" onChange={handleImageChange}/>
                      </label>
                  </div>
                </div>
                 : 
                <div className="w-full md:w-2/3 px-3">
                    <img className="w-60 px-3 h-96" src={currentProduk.Foto_URL}/>
                    <div className="flex mt-5 items-center justify-center w-full">
                      <label
                          className="flex flex-col w-full h-100 border-4 border-blue-200 border-dashed hover:bg-gray-100 hover:border-gray-300">
                          <div className="flex flex-col items-center justify-center pt-7">
                              <svg xmlns="http://www.w3.org/2000/svg" className="w-8 h-8 text-gray-400 group-hover:text-gray-600"
                                  fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12" />
                              </svg>
                              <p className="pt-1 text-sm tracking-wider text-gray-400 group-hover:text-gray-600">
                                  Update Photo</p>
                          </div>
                          <input id="upload-image-produk" type="file" accept=".jpg,.png,.png,.jpeg" className="opacity-0" onChange={handleImageChange}/>
                      </label>
                  </div>
                </div>
              }  
            </div>

            {/** Divider 1*/}
            <div className="flex flex-wrap w-full mb-6 border-dashed border-t-2 border-gray-200">
              <div className="w-full md:w-1/3 px-3 mt-10 mb-6 md:mb-0">
                <h1 className="font-sans font-bold text-2xl">Informasi Produk</h1>
              </div>
            </div>

            {/** Judul */}
            <div className="flex flex-wrap w-full mb-10">
              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <h1 className="font-['Open_Sans'] font-bold text-xl mb-2">Judul</h1>
                <p className="text-sm font-['Open_Sans']">
                Cantumkan Judul produk anda dengan jelas.
                </p>
              </div>
              <div className="w-full md:w-2/3 px-3">
                <label className="block text-sm mt-10">
                  <input
                    className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                    onChange={handleJudulChange}
                    value={judul || currentProduk.Judul}
                  />
                </label>
              </div>  
            </div>
            
            {/** Informasi Detail */}
            <div className="flex flex-wrap w-full mb-10">
              <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                <h1 className="font-['Open_Sans'] font-bold text-xl mb-2">Informasi Detail</h1>
                <p className="text-sm font-['Open_Sans']">
                Berikan Informasi Sedetail mungkin tentang produk anda.
                </p>
              </div>
              <div className="w-full md:w-2/3 px-3 mt-6">
                <label className="block mt-4 text-sm">
                  <Editor
                    apiKey={window.env.REACT_APP_TINY_MCE_API_KEY}
                    initialValue={currentProduk.Informasi_Detail}
                    init={{
                      height: 600,
                      menubar: false,
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                      ],
                      toolbar:
                        "undo redo | formatselect | bold italic backcolor forecolor | alignleft aligncenter alignright alignjustify table | bullist numlist outdent indent link | removeformat | help",
                    }}
                    onEditorChange={handleEditorChange}
                  />
                </label>
              </div>  
            </div>

            <div className="flex flex-wrap justify-center w-full mb-10">
              <button
                type="submit"
                className="w-full md:w-1/3 flex justify-center py-2 my-1 rounded-lg text-white items-center text-sm bg-green-700 focus:outline-none"
              >
                Simpan
              </button>
            </div>
          </form>
				</div>
			</div>
		</main>
    </div>
  );
}
