import { formatRupiah, statusTransaksi } from "../../utils";

export default function Row({ data, idx }) {
  return (
    <tr className="text-gray-700 dark:text-gray-400">
      <td className="px-4 py-6 text-sm">{idx + 1}</td>
      <td className="px-4 py-6 text-sm">{data.Kode_Transaksi}</td>
      <td className="px-4 py-6 text-sm truncate">{data.CU_Id}</td>
      <td className="px-4 py-6 text-sm truncate">{data.Nama_CU}</td>
      <td className="px-4 py-6 text-sm truncate">{data.ID_Anggota}</td>
      <td className="px-4 py-6 text-sm truncate">{data.No_BA}</td>
      <td className="px-4 py-6 text-sm truncate">{data.Nama_Anggota}</td>
      <td className="px-4 py-6 text-sm truncate">
        {data.Nama_Jenis_Transaksi_Detail}
      </td>
      <td className="px-4 py-6 text-sm">
        {statusTransaksi(data.Billers_Status)}
      </td>
      <td className="px-4 py-6 text-sm">{data.Tgl_Transaksi}</td>
      <td className="px-4 py-6 text-sm">{data.Jam_Transaksi}</td>
      <td className="px-4 py-6 text-sm truncate">
        {formatRupiah(data.Nominal_CUSO_Pay)}
      </td>
      <td className="px-4 py-6 text-sm truncate">
        {formatRupiah(data.Nominal_Biaya_Transaksi)}
      </td>
      <td className="px-4 py-6 text-sm truncate">
        {formatRupiah(data.Rev_Share_CUSO)}
      </td>
      <td className="px-4 py-6 text-sm truncate">
        {formatRupiah(data.Rev_Share_COOP)}
      </td>
      <td className="px-4 py-6 text-sm truncate">
        {formatRupiah(data.CUSO_Point_1)}
      </td>
      <td className="px-4 py-6 text-sm truncate">
        {formatRupiah(data.CUSO_Point_2)}
      </td>
      {/* <td className="px-4 py-6 text-sm truncate">
        {formatRupiah(data.Saldo_Anggota)}
      </td> */}
      <td className="px-4 py-6 text-sm truncate">{data.Keterangan}</td>
    </tr>
  );
}
