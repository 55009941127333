import { formatRupiah } from "../../utils";

export default function Row({ data, idx }) {
  return (
    <tr className="text-gray-700 dark:text-gray-400">
      <td className="px-4 py-3 text-sm">{idx + 1}</td>
      <td className="px-4 py-3 text-sm">{data.ID_Anggota}</td>
      <td className="px-4 py-3 text-sm">{data.No_BA}</td>
      <td className="px-4 py-3 text-sm truncate">{data.Nama_Anggota}</td>
      <td className="px-4 py-3 text-sm">
        {data.Tipe_Transaksi === 0 ? "Penerimaan" : "Pengeluaran"}
      </td>
      <td className="px-4 py-3 text-sm truncate">
        {formatRupiah(data.Nominal)}
      </td>
      <td className="px-4 py-3 text-sm">{data.Tgl_Transaksi}</td>
      <td className="px-4 py-3 text-sm">{data.Jam_Transaksi}</td>
      <td className="px-4 py-3 text-sm truncate">{data.Keterangan}</td>
    </tr>
  );
}
