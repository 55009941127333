const initialState = {
	listInfoProduk: [],
	listCU:[],
	currentProduk: {},
	isLoading: false,
	isSuccessAdd: false,
	isError: "",
};

export default function infoProdukReducer(state = initialState, action) {
	switch (action.type) {
		case "SET_LIST_INFO_PRODUK":
			return {
				...state,
				listInfoProduk: action.payload,
				isSuccessAdd: false,
				isLoading: false,
			};
		case "SET_LIST_CU":
			return {
				...state,
				listCU: action.payload,
				isSuccessAdd: false,
				isLoading: false,
			};
		case "SET_CURRENT_PRODUK":
			return {
				...state,
				currentProduk: action.payload,
				isSuccessAdd: false,
				isLoading: false,
			};
		case "SET_INFO_PRODUK_LOADING":
			return {
				...state,
				isLoading: true,
			};
		case "SET_SUCCESS_ADD":
			return {
				...state,
				isLoading: false,
				isSuccessAdd: true,
			};
		default:
			return state;
	}
}
