import { useRouteMatch, Route, Switch } from "react-router-dom";
import { TableDepositTopUp, TableDepositTopUpHistory } from "../components";

export default function DepositTopupPage() {
  const { path } = useRouteMatch();

  return (
    <div>
      <Switch>
        <Route exact path={path}>
          <TableDepositTopUp />
        </Route>
        <Route exact path={`${path}/mutasi-topup/:id`}>
          <TableDepositTopUpHistory />
        </Route>
      </Switch>
    </div>
  );
}
