import { useState, useRef, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2"
import { fetchBerita, fetchCU, filterKoperasiBerita } from "../../store/actions/beritaAction";

export default function ListBerita() {
    const history = useHistory();
    const dispatch = useDispatch();
    let { listBerita } = useSelector((state) => state.berita);
    let { listCU } = useSelector((state) => state.ruangUsaha);


    useEffect(() => {
        const expired = +localStorage.getItem("expired")
        const now = new Date()
    
        if(localStorage.getItem("expired") && expired < now.getTime()){
            localStorage.clear();
            window.location.reload()
        }
        
        dispatch(fetchBerita());
        dispatch(fetchCU());
    }, [dispatch]);

    function handleFilterKoperasi(e){
        if(e.target.value == 'all'){
            dispatch(fetchBerita());
        }else{
            dispatch(filterKoperasiBerita(e.target.value, listBerita));
        }
    }

	return (
		<main className="h-full pb-16 overflow-y-auto">
            {/** Component atas */}
            <div className="flex ml-10 mr-10 justify-center flex-wrap mb-0">
                {/** Button tambah ruang usaha */}
                <div className="w-full md:w-1/5 px-3 mb-6 mt-3 md:mb-0">
                    <button
                        className="w-full md:w-full flex justify-center items-center py-2 my-1 rounded-lg text-white items-center text-sm bg-blue-700 focus:outline-none"
                    >
                        <Link
                            className="inline-flex justify-center items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-500 dark:hover:text-gray-200"
                            to="/berita"
                            >
                            <svg
                                className="w-5 h-5"
                                aria-hidden="true"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            > 
                                <path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 8l-5-5-5 5M12 4.2v10.3"></path>
                            </svg>
                            <span className="ml-4">Tambah Berita</span>
                        </Link>
                    </button>
                </div>

                {/** Filter Koperasi*/}
                <div className="w-full md:w-2/5 px-3 mb-6 mt-5 md:mb-0">
                    {/** Nama Koperasi (untuk admin) */}
                    {
                        localStorage.getItem('CU_Id') === 'null' ? <div>
                            {/** CU_Id */}
                            <div className="flex flex-wrap w-full mb-10">
                            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            </div>
                            <div className="w-full md:w-2/3 px-3">
                                <select onChange={handleFilterKoperasi} className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                                    <option value="all">Semua Koperasi</option>
                                    {listCU.map((data, idx) => {
                                    return <option value={data.CU_Id} key={idx}>{data.Nama_CU}</option>;
                                    })}                    
                                </select>
                                
                            </div>  
                            </div>
                        </div>:<div>
                        </div>
                    }
                </div>
            </div>

            {/** List Produk */}
			<div className="h-full bg-slate-100 flex justify-left ml-8 flex-wrap">
                
                {/** Card */}
                {
                    listBerita ? listBerita.map((produk, idx) => {
                        return <div key={idx} className="bg-slate-50 shadow-lg md:w-1/5 h-85 mt-10 mx-5 rounded-lg flex flex-col content-center overflow-hidden relative">
                                    <div className="bg-yellow-300">
                                        <img
                                            className="h-36 w-full object-cover"
                                            src={produk.Foto_URL}
                                            alt=""
                                        />
                                    </div>
                                    <div className="h-8 ml-3 mt-3 text-sm font-['Open_Sans']">
                                        {listCU.find(cu => cu.CU_Id === produk.CU_Id) ? listCU.find(cu => cu.CU_Id === produk.CU_Id).Nama_CU :''}
                                    </div>
                                    <div className="h-15 mx-3 mb-3 flex flex-col font-['Open_Sans']">
                                        {produk.Judul.length < 20 ? <div className="font-bold">{produk.Judul}</div> : <div className="font-bold">{produk.Judul.slice(0,18)}...</div> }
                                        <div className="text-xs font-semibold font-['Open_Sans']">{produk.Tanggal.split('T')[0].split("-").reverse().join("-")}</div>   
                                    </div>
                                    <div className="m-3 h-10 font-semibold text-sm font-['Open_Sans']">{produk.Harga}</div>
                                    <div className="flex flex-wrap h-14 justify-center w-full mb-2">
                                        <Link to={`/update-berita/${produk.Kode_News}`}>
                                            <button
                                                type="button"
                                                className="w-full m-3 flex justify-center py-2 my-1 rounded-lg text-white items-center text-sm bg-green-700 focus:outline-none"
                                            >  
                                                <svg
                                                    className="w-5 h-5"
                                                    aria-hidden="true"
                                                    fill="none"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                > 
                                                    <path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path>
                                                    <polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon>
                                                </svg>
                                                Edit
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                    }) : <div>
                        <svg className="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
                        </svg>
                        Harap Tunggu...
                    </div>
                } 
			</div>
		</main>
	);
}
