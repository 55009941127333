export default function Row({ data, idx }) {
  return (
    <tr className="text-gray-700 dark:text-gray-400">
      <td className="px-4 py-6 text-sm">{idx + 1}</td>
      <td className="px-4 py-6 text-sm">{data.name}</td>
      <td className="px-4 py-6 text-sm truncate">{data.username}</td>
      <td className="px-4 py-6 text-sm truncate">{data.tipe_user}</td>
      <td className="px-4 py-6 text-sm truncate">{data.email}</td>
      <td className="px-4 py-6 text-sm truncate">{data.cu_id || "-"}</td>
      <td className="px-4 py-6 text-sm">
        <button
          className="flex items-center justify-between px-2 py-1 text-sm font-medium leading-5 text-purple-600 rounded-xl dark:text-purple-600 focus:outline-none focus:shadow-outline-gray bg-gray-200"
          aria-label="Edit"
        >
          Edit
        </button>
      </td>
    </tr>
  );
}
