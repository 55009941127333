import { useState, useRef, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2"
import { fetchInfoProduk, fetchCU} from "../../store/actions/infoProdukAction";

export default function ListInfoProduk() {
    const history = useHistory();
    const dispatch = useDispatch();
    let { listInfoProduk, listCU } = useSelector((state) => state.infoProduk);

    useEffect(() => {
        const expired = +localStorage.getItem("expired")
        const now = new Date()
    
        if(localStorage.getItem("expired") && expired < now.getTime()){
            localStorage.clear();
            window.location.reload()
        }
        
        if(localStorage.getItem("CU_Id") != 'null') {
            dispatch(fetchInfoProduk(localStorage.getItem("CU_Id")));
        };
        dispatch(fetchCU());
    }, [dispatch]);

    const [CU_Id, setCU_Id] = useState(localStorage.getItem("CU_Id"));

    function handleFilterKoperasi(e){
        if(e.target.value == 'all'){
            // dispatch(fetchRuangUsaha());
        }else{
            dispatch(fetchInfoProduk(e.target.value));
            setCU_Id(e.target.value)
        }
    }

	return (
		<main className="h-full pb-16 overflow-y-auto">
            {/** Component atas */}
            <div className="flex ml-10 mr-10 justify-center flex-wrap mb-0">
                {/** Button tambah ruang usaha */}
                <div className="w-full md:w-1/5 px-3 mb-6 mt-3 md:mb-0">
                </div>

                {/** Filter Koperasi*/}
                <div className="w-full md:w-2/5 px-3 mb-6 mt-5 md:mb-0">
                    {/** Nama Koperasi (untuk admin) */}
                    {
                        localStorage.getItem('CU_Id') === 'null' ? <div>
                            {/** CU_Id */}
                            <div className="flex flex-wrap w-full mb-10">
                            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            </div>
                            <div className="w-full md:w-2/3 px-3">
                                <select onChange={handleFilterKoperasi} className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                                    <option selected disabled>Pilih Koperasi</option>
                                    {listCU.map((data, idx) => {
                                    return <option value={data.CU_Id} key={idx}>{data.Nama_CU}</option>;
                                    })}                    
                                </select>
                                
                            </div>  
                            </div>
                        </div>:<div>
                        </div>
                    }
                </div>

                {/** Search bar*/}
                <div className="w-full md:w-2/5 px-3 mb-6 mt-5 md:mb-0">
                   
                </div>
            </div>

            {/** List Produk */}
			<div className="h-full bg-slate-100 flex justify-center mx-8 flex-wrap">
                
                {/** Card */}
                {
                    listInfoProduk ? listInfoProduk.map((produk, idx) => {
                        return <div key={idx} className="bg-slate-50 shadow-lg md:w-2/5 h-200 mt-10 mx-5 rounded-lg flex flex-col content-center overflow-hidden relative">
                                    <div className="bg-yellow-300">
                                        <img
                                            className="h-72 w-full object-cover"
                                            src={produk.Foto_URL}
                                            alt=""
                                        />
                                    </div>
                                    <div className="h-10 mx-3 mb-3 flex flex-col text-2xl font-['Open_Sans']">
                                        {produk.Judul.length < 20 ? <div className="font-bold">{produk.Judul}</div> : <div className="font-bold">{produk.Judul.slice(0,18)}...</div> }   
                                    </div>
                                    <div className="h-120 mx-5 mb-3 flex flex-col font-['Open_Sans']">
                                        <div dangerouslySetInnerHTML={{__html: produk.Informasi_Detail}}></div>
                                    </div>
                                    <div className="flex flex-wrap h-14 justify-center w-full mb-2">
                                    <Link to={`/update-produk/${CU_Id}/${produk.Kode_Produk}`}>
                                        <button
                                            type="button"
                                            className="w-full m-3 flex justify-center py-2 my-1 rounded-lg text-white items-center text-sm bg-green-700 focus:outline-none"
                                        >  
                                            <svg
                                                className="w-5 h-5"
                                                aria-hidden="true"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                viewBox="0 0 24 24"
                                                stroke="currentColor"
                                            > 
                                                <path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path>
                                                <polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon>
                                            </svg>
                                            Update
                                        </button>
                                    </Link>
                                    </div>
                                </div>
                    }) : <div>
                        <svg className="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
                        </svg>
                        Harap Tunggu...
                    </div>
                } 
			</div>
		</main>
	);
}
