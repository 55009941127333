export default function ButtonLoading() {
	return (
		<button
			type="button"
			className="w-full flex justify-center py-2 my-1 rounded-lg text-white items-center text-sm bg-purple-600"
			disabled={true}
		>
			<svg className="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
				<circle
					className="opacity-10"
					cx="12"
					cy="12"
					r="10"
					stroke="#fff"
					strokeWidth="4"
				></circle>
				<path
					className="opacity-75"
					fill="#fff"
					d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
				></path>
			</svg>
			Processing
		</button>
	);
}
