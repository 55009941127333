import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function InputSimpleDatePicker({ handleChange, value }) {
	const [startDate, setStartDate] = useState(value);
	const onChangeHandler = (newDate) => {
		setStartDate(newDate);
		if (newDate) {
			let date = new Date(newDate);
			date.setDate(date.getDate());
			handleChange(date.toISOString().split("T")[0]);
		} else {
			handleChange("");
		}
	};
	return (
		<div>
			<DatePicker
				className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
				selected={startDate}
				placeholderText="dd/mm/yyyy"
				dateFormat="dd/MM/yyyy"
				// isClearable
				onChange={(date) => onChangeHandler(date)}
			/>
		</div>
	);
}
