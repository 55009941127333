import { useRouteMatch, Route, Switch } from "react-router-dom";
import { AddUser, ListUser } from "../components";

export default function UserManagementPage() {
	const { path } = useRouteMatch();
	return (
		<div>
			<Switch>
				<Route exact path={path}>
					<ListUser />
				</Route>
				<Route exact path={`${path}/tambah-user`}>
					<AddUser />
				</Route>
			</Switch>
		</div>
	);
}
