import { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleLogin } from "../../store/actions/userAction";
import ButtonLoading from "../Button/ButtonLoading";
import ModalResetPassword from "./ModalResetPassword";
import logo from "../../assets/logo.jpg";

export default function LoginForm() {
  const { isLogin, isLoading } = useSelector((state) => state.user);
  const [input, setInput] = useState({
    username: "",
    password: "",
  });
  const inputRef = useRef(null);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (isLogin) {
      history.push("/");
      setInput({
        username: "",
        password: "",
      });
    }
  }, [isLogin, history]);

  const submitLogin = (e) => {
    e.preventDefault();
    dispatch(handleLogin(input));
  };

  const onChangeHandler = (e) => {
    const { name, value } = e.target;
    setInput({
      ...input,
      [name]: value,
    });
  };

  //open modal
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="flex items-center min-h-screen p-6 bg-gray-50 dark:bg-gray-900">
      <ModalResetPassword setOpenModal={setOpenModal} openModal={openModal} />
      <div className="flex-1 h-full max-w-4xl mx-auto overflow-hidden bg-white rounded-lg shadow-xl dark:bg-gray-800">
        <div className="flex flex-col overflow-y-auto md:flex-row">
          <div className="h-32 md:h-auto md:w-1/2">
            <img
              className="object-cover w-full h-full dark:hidden"
              src={logo}
              alt="Office"
            />
            <img
              aria-hidden="true"
              className="hidden object-cover w-full h-full dark:block"
              src="../assets/andre-benz-cXU6tNxhub0-unsplash.jpg"
              alt="Office"
            />
          </div>
          <div className="flex items-center justify-center p-6 sm:p-12 md:w-1/2">
            <div className="w-full">
              <h1 className="mb-4 text-xl font-semibold text-gray-700 dark:text-gray-200">
                Login
              </h1>
              <form
                onSubmit={(e) => {
                  submitLogin(e);
                }}
              >
                <label className="block text-sm">
                  <span className="text-gray-700 dark:text-gray-400">
                    Username
                  </span>
                  <input
                    className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                    name="username"
                    ref={inputRef}
                    value={input.username}
                    onChange={(e) => onChangeHandler(e)}
                    required
                  />
                </label>
                <label className="block mt-4 text-sm">
                  <span className="text-gray-700 dark:text-gray-400">
                    Password
                  </span>
                  <input
                    className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
                    name="password"
                    value={input.password}
                    onChange={(e) => onChangeHandler(e)}
                    type="password"
                    required
                  />
                </label>

                {/* <!-- You should use a button here, as the anchor is only used for the example  --> */}
                <div className="mt-4">
                  {isLoading ? (
                    <ButtonLoading />
                  ) : (
                    <button
                      type="submit"
                      className="w-full flex justify-center py-2 my-1 rounded-lg text-white items-center text-sm bg-purple-600 focus:outline-none"
                    >
                      Log in
                    </button>
                  )}
                </div>
              </form>
              {/* <p className="text-gray-700 my-2">
								Belum punya akun?, silakan
								<Link to="/register">
									<span className="text-purple-600 mx-2">Register</span>
								</Link>
							</p> */}
              <p className="text-gray-700 my-2">
                Lupa password ?
                <button
                  onClick={() => setOpenModal(true)}
                  className="text-purple-600 mx-2 focus:outline-none"
                >
                  Reset password
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
