import axios from "../../config/axiosinstance";
import Swal from "sweetalert2";

export const handleLogin = (payload) => {
  return (dispatch) => {
    dispatch({
      type: "SET_LOGIN_LOADING",
      payload: true,
    });
    axios({
      url: "login",
      method: "POST",
      data: payload,
    })
      .then(({ data }) => {
        localStorage.setItem("token", data.token);
        localStorage.setItem("expired", data.expired)
        return axios({
          method: "GET",
          headers: {
            token: data.token,
          },
        });
      })
      .then(({ data }) => {
        localStorage.setItem("CU_Id", data.CU_Id);
        localStorage.setItem("id", data.tipe_user);
        localStorage.setItem("name", data.name);
        dispatch({
          type: "SET_LOGIN_STATUS",
          payload: {
            isLogin: true,
            tipeUser: data.tipe_user,
            cuId: data.CU_Id,
          },
        });
        dispatch({
          type: "SET_LIST_JENIS_TRANSAKSI",
          payloadPPOB: data.jenisTransaksiPPOB,
          payloadViaBank: data.jenisTransaksiViaBank,
        });
        // SET JENIS TRANSAKSI ON LOCAL STORAGE
        localStorage.setItem("jenis_tr_ppob", JSON.stringify(data.jenisTransaksiPPOB));
        localStorage.setItem("jenis_tr_viaBank", JSON.stringify(data.jenisTransaksiViaBank));
      })
      .catch(({ response }) => {
        dispatch({
          type: "RESET_LOGIN_LOADING",
        });
        Swal.fire({
          title: "Oops!",
          text: response.data.message,
          icon: "error",
          showConfirmButton: true,
          position: "center",
          allowOutsideClick: false,
        });
      });
  };
};

export const handleLogout = () => {
  return (dispatch) => {
    dispatch({
      type: "SET_LOGIN_STATUS",
      payload: false,
    });
  };
};

// export const getDepositSetoran = () => {
// 	return (dispatch) => {
// 		axios({
// 			url: "dsetoran/list?page=1&limit=100",
// 			method: "GET",
// 			headers: localStorage.getItem("token"),
// 		})
// 			.then(({ data }) => {
// 				dispatch({
// 					type: "SET_DEPOSIT_SETORAN",
// 					payload: data,
// 				});
// 			})
// 			.catch((err) => {
// 				console.log(err);
// 			});
// 	};
// };

export const setMenuBarOpen = (payload) => {
  return (dispatch) => {
    dispatch({
      type: "SET_MENU_BAR_OPEN",
      payload,
    });
  };
};
