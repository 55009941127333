import React from "react";
import { ResetPassword } from "../components";

export default function ResetPasswordPage() {
	return (
		<>
			<ResetPassword />
		</>
	);
}
