import { useState, useRef, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2"
import { fetchRuangUsaha, fetchCU, searchRuangUsaha, filterKoperasiRuangUsaha, deleteRuangUsaha} from "../../store/actions/ruangUsahaAction";

export default function ListRuangUsaha() {
    const history = useHistory();
    const dispatch = useDispatch();
    let { listRuangUsaha, listCU } = useSelector((state) => state.ruangUsaha);

    useEffect(() => {
        const expired = +localStorage.getItem("expired")
        const now = new Date()
    
        if(localStorage.getItem("expired") && expired < now.getTime()){
            localStorage.clear();
            window.location.reload()
        }
        
        dispatch(fetchRuangUsaha());
        dispatch(fetchCU());
    }, [dispatch]);

    const [search, setSearch] = useState('')

    function handleSearchChange(e){
        if(e.target.value.length){
          setSearch(e.target.value)
        }else{
          setSearch("")
        }
    }

    function handleSearch(e){
        e.preventDefault()
        dispatch(searchRuangUsaha(search));
    }

    function handleFilterKoperasi(e){
        if(e.target.value == 'all'){
            dispatch(fetchRuangUsaha());
        }else{
            dispatch(filterKoperasiRuangUsaha(e.target.value, listRuangUsaha));
        }
    }

    function handleDelete(kode){
        Swal.fire({
          title: 'Apakah Anda Yakin?',
          text: "Produk yang telah dihapus tidak dapat dikembalikan!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ya, Hapus!'
        }).then((result) => {
          if (result.value) {
            dispatch(deleteRuangUsaha(kode));
          }
        })
    }

	return (
		<main className="h-full pb-16 overflow-y-auto">
            {/** Component atas */}
            <div className="flex ml-10 mr-10 justify-center flex-wrap mb-0">
                {/** Button tambah ruang usaha */}
                <div className="w-full md:w-1/5 px-3 mb-6 mt-3 md:mb-0">
                    <button
                        className="w-full md:w-full flex justify-center items-center py-2 my-1 rounded-lg text-white items-center text-sm bg-blue-700 focus:outline-none"
                    >
                        <Link
                            className="inline-flex justify-center items-center w-full text-sm font-semibold transition-colors duration-150 hover:text-gray-500 dark:hover:text-gray-200"
                            to="/ruang-usaha"
                            >
                            <svg
                                className="w-5 h-5"
                                aria-hidden="true"
                                fill="none"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            > 
                                <path d="M3 15v4c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-4M17 8l-5-5-5 5M12 4.2v10.3"></path>
                            </svg>
                            <span className="ml-4">Tambah Produk</span>
                        </Link>
                    </button>
                </div>

                {/** Filter Koperasi*/}
                <div className="w-full md:w-2/5 px-3 mb-6 mt-5 md:mb-0">
                    {/** Nama Koperasi (untuk admin) */}
                    {
                        localStorage.getItem('CU_Id') === 'null' ? <div>
                            {/** CU_Id */}
                            <div className="flex flex-wrap w-full mb-10">
                            <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                            </div>
                            <div className="w-full md:w-2/3 px-3">
                                <select onChange={handleFilterKoperasi} className="block appearance-none w-full bg-white border border-gray-400 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                                    <option value="all">Semua Koperasi</option>
                                    {listCU.map((data, idx) => {
                                    return <option value={data.CU_Id} key={idx}>{data.Nama_CU}</option>;
                                    })}                    
                                </select>
                                
                            </div>  
                            </div>
                        </div>:<div>
                        </div>
                    }
                </div>

                {/** Search bar*/}
                <div className="w-full md:w-2/5 px-3 mb-6 mt-5 md:mb-0">
                    <div className="flex justify-center">
                        <div className="mb-3 xl:w-96">
                            <div className="input-group relative flex flex-wrap items-center w-full mb-4">
                                <input 
                                    type="search" 
                                    className="form-control relative flex-auto min-w-0 block w-5/6 px-3 py-1.5 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none" 
                                    placeholder="Search" 
                                    aria-label="Search" 
                                    aria-describedby="button-addon2" 
                                    onChange={handleSearchChange}
                                />
                                <button 
                                    className="btn w-1/6 inline-block px-6 py-2.5 justify-center items-center bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700  focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out flex items-center" 
                                    type="button" 
                                    id="button-search"
                                    onClick={handleSearch}
                                    >
                                    <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="search" className="w-4" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path fill="currentColor" d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"></path>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/** List Produk */}
			<div className="h-full bg-slate-100 flex justify-left ml-8 flex-wrap">
                
                {/** Card */}
                {
                    listRuangUsaha ? listRuangUsaha.map((produk, idx) => {
                        return <div key={idx} className="bg-slate-50 shadow-lg md:w-1/5 h-85 mt-10 mx-5 rounded-lg flex flex-col content-center overflow-hidden relative">
                                    <div className="bg-yellow-300">
                                        <img
                                            className="h-36 w-full object-cover"
                                            src={produk.Foto_URL}
                                            alt=""
                                        />
                                    </div>
                                    <div className="h-8 ml-3 mt-3 text-sm font-['Open_Sans']">
                                        {listCU.find(cu => cu.CU_Id === produk.CU_Id) ? listCU.find(cu => cu.CU_Id === produk.CU_Id).Nama_CU :''}
                                    </div>
                                    <div className="h-15 mx-3 mb-3 flex flex-col font-['Open_Sans']">
                                        {produk.Judul.length < 20 ? <div className="font-bold">{produk.Judul}</div> : <div className="font-bold">{produk.Judul.slice(0,18)}...</div> }
                                        <div className="text-xs font-semibold font-['Open_Sans']">{produk.Tanggal.split('T')[0].split("-").reverse().join("-")}</div>
                                        <div>{`Dilihat sebanyak ${produk.Hit_Counter} kali`}</div>    
                                    </div>
                                    <div className="m-3 h-10 font-semibold text-sm font-['Open_Sans']">{produk.Harga}</div>
                                    <div className="flex flex-wrap h-14 justify-center w-full mb-2">
                                        <Link to={`/update-ruang-usaha/${produk.Kode}`}>
                                            <button
                                                type="button"
                                                className="w-full m-3 flex justify-center py-2 my-1 rounded-lg text-white items-center text-sm bg-green-700 focus:outline-none"
                                            >  
                                                <svg
                                                    className="w-5 h-5"
                                                    aria-hidden="true"
                                                    fill="none"
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    viewBox="0 0 24 24"
                                                    stroke="currentColor"
                                                > 
                                                    <path d="M20 14.66V20a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h5.34"></path>
                                                    <polygon points="18 2 22 6 12 16 8 16 8 12 18 2"></polygon>
                                                </svg>
                                                Edit
                                            </button>
                                        </Link>
                                    </div>
                                </div>
                    }) : <div>
                        <svg className="animate-spin h-5 w-5 mr-3 ..." viewBox="0 0 24 24">
                        </svg>
                        Harap Tunggu...
                    </div>
                } 
			</div>
		</main>
	);
}
