import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import axios from "../../config/axiosinstance";

export default function ModalResetPassword({ openModal, setOpenModal }) {
	const [email, setEmail] = useState("");
	const [notif, setNotif] = useState("");
	const headTitle = useRef();

	const handleSubmit = () => {
		axios({
			url: "reset",
			method: "POST",
			data: { email },
		})
			.then(({ data }) => {
				setNotif(data.message);
			})
			.catch(({ response }) => {
				// setNotif(response.data.message);
				console.log(response);
			});
	};

	useEffect(() => {
		setEmail("");
		setNotif("");
	}, []);

	return (
		<Transition show={openModal} as={Fragment}>
			<Dialog
				as="div"
				className="fixed inset-0 z-10 overflow-y-auto"
				initialFocus={headTitle}
				static
				open={openModal}
				onClose={() => setOpenModal(false)}
			>
				<div className="min-h-screen px-4 text-center">
					<Dialog.Overlay className="fixed inset-0 opacity-30 bg-black" />
					<span
						className="inline-block h-screen align-middle"
						aria-hidden="true"
					>
						&#8203;
					</span>
					<Transition.Child
						as={Fragment}
						enter="ease-out duration-300"
						enterFrom="opacity-0 scale-95"
						enterTo="opacity-100 scale-100"
						leave="ease-in duration-200"
						leaveFrom="opacity-100 scale-100"
						leaveTo="opacity-0 scale-95"
					>
						<div className="inline-block w-full max-w-3xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
							<Dialog.Title
								as="h3"
								className="text-lg font-medium leading-6 text-gray-700"
							>
								<h1 ref={headTitle}>
									Masukan email anda untuk mereset password
								</h1>
							</Dialog.Title>
							<div className="mt-6">
								{notif ? (
									<p className="leading-6 text-gray-700">
										Link untuk mereset password berhasil dikirim, {notif}
									</p>
								) : (
									<label className="block text-sm">
										<input
											className="block w-full mt-1 text-sm dark:border-gray-600 dark:bg-gray-700 focus:border-purple-400 focus:outline-none focus:shadow-outline-purple dark:text-gray-300 dark:focus:shadow-outline-gray form-input"
											name="username"
											placeholder="email"
											value={email}
											onChange={(e) => setEmail(e.target.value)}
											required
										/>
									</label>
								)}
							</div>
							<div className="mt-4 flex justify-end gap-4">
								{!notif && (
									<button
										type="button"
										className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-purple-600 border border-transparent rounded-md hover:bg-purple-700 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-purple-600"
										onClick={handleSubmit}
									>
										Kirim
									</button>
								)}
								<button
									type="button"
									className="inline-flex justify-center px-4 py-2 text-sm font-medium text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500"
									onClick={() => setOpenModal(false)}
								>
									Close
								</button>
							</div>
						</div>
					</Transition.Child>
				</div>
			</Dialog>
		</Transition>
	);
}
