import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loading } from "../index";
import { Link, useRouteMatch } from "react-router-dom";
import { fetchUsers } from "../../store/actions/userManagementAction";
import Row from "./Row";

export default function ListUser() {
  const { listUsers, isLoading } = useSelector((state) => state.userManagement);
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  useEffect(() => {
    const expired = +localStorage.getItem("expired")
    const now = new Date()

    if(localStorage.getItem("expired") && expired < now.getTime()){
        localStorage.clear();
        window.location.reload()
    }
    
    dispatch(fetchUsers());
  }, [dispatch]);

  if (isLoading)
    return (
      <>
        <Loading />
      </>
    );
  return (
    <main className="pb-10">
      <div className="container px-6 mx-auto">
        <div className="flex justify-between items-center">
          <h2 className="my-6 text-2xl font-semibold text-gray-700 dark:text-gray-200">
            List User
          </h2>
          <Link to={`${url}/tambah-user`}>
            <button className="px-4 py-2 m-1 rounded-lg text-white items-center text-sm bg-purple-600 hover:bg-purple-800 focus:outline-none">
              Tambah User
            </button>
          </Link>
        </div>
        <div className="w-full overflow-hidden rounded-lg shadow-md">
          <div className="sticky top-0 w-full overflow-x-auto h-85vh">
            <table className="relative w-full whitespace-no-wrap">
              <thead>
                <tr className="text-xs font-semibold tracking-wide text-left text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800">
                  <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800">
                    No
                  </th>
                  <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800">
                    Nama
                  </th>
                  <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800">
                    Username
                  </th>
                  <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800">
                    Tipe User
                  </th>
                  <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800">
                    Email
                  </th>
                  <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800">
                    CU ID
                  </th>
                  <th className="sticky top-0 px-4 py-3 text-gray-500 uppercase border-b dark:border-gray-700 bg-gray-100 dark:text-gray-400 dark:bg-gray-800">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y dark:divide-gray-700 dark:bg-gray-800">
                {/* <p className="truncate text-gray-500 dark:text-gray-400 px-4 py-2">
										Belum ada user
									</p> */}
                {listUsers?.map((data, idx) => {
                  return <Row key={idx} data={data} idx={idx} />;
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </main>
  );
}
