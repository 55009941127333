const initialState = {
	namaKoperasi: "",
	depositSetoran: [],
	totalSaldo: 0,
	historySetoran: [],
	isLoading: false,
	isLoadingButton: false,
	isError: false,
};

function setoranReducer(state = initialState, action) {
	switch (action.type) {
		case "SET_DEPOSIT_SETORAN":
			return {
				...state,
				depositSetoran: action.payload,
				isLoading: false,
				isLoadingButton: false,
				isError: false,
			};
		case "SET_SALDO_SETORAN":
			return {
				...state,
				totalSaldo: action.payload,
				isLoading: false,
				isLoadingButton: false,
				isError: false,
			};
		case "SET_HISTORY_SETORAN":
			return {
				...state,
				historySetoran: action.payload,
				namaKoperasi: action.namaKoperasi,
				isLoading: false,
				isLoadingButton: false,
				isError: false,
			};
		case "SET_SETORAN_LOADING":
			return {
				...state,
				isLoading: true,
				isError: false,
			};
		case "SET_BUTTON_SETORAN_LOADING":
			return {
				...state,
				isLoadingButton: true,
				isError: false,
			};
		case "SET_SETORAN_ERROR":
			return {
				...state,
				isLoading: false,
				isLoadingButton: false,
				isError: true,
			};
		default:
			return state;
	}
}

export default setoranReducer;
